<section class="flex-container {{widgetOrientation}}" [ngClass]="clpContents?.quoteWidget ? 'includeWidget' : 'noWidget'">

  <!-- For Horizontal or no widget layouts -->
  <ng-container *ngIf="widgetOrientation === 'horizontal' || !widgetOrientation">

    <div class="flex-row-wrapper" [ngClass]="{'containsWidget': top}" #rowOne>

      <app-widget-wrapper *ngIf="top" [ngClass]="position === 'left' ? 'start' : 'end'"></app-widget-wrapper>
      <ng-container *ngFor="let content of clpContents?.top | mapToIterable">
        <div [style.width]="rowOneWidth + '%'" *ngIf="content.val" class="item-wrapper" [ngClass]="position !== 'left' ? 'start' : 'end'">
          <div class="flex-item" [innerHTML]="content.val | sanitizeHtml"></div>
        </div>
      </ng-container>
      <img id = "heroImage"  *ngIf="widgetOnHero && top" [src]="clpHeroImage" class="hero-img-clp" alt="altName" [class.nestled]="nestled" />
    </div>
  
    <div class="flex-row-wrapper" [ngClass]="{'containsWidget': middle}" #rowTwo>
      <app-widget-wrapper *ngIf="middle" [ngClass]="position === 'left' ? 'start' : 'end'"></app-widget-wrapper>
      <ng-container *ngFor="let content of clpContents?.middle | mapToIterable">
        <div [style.width]="rowTwoWidth + '%'" *ngIf="content.val" class="item-wrapper" [ngClass]="position !== 'left' ? 'start' : 'end'">
          <div class="flex-item" [innerHTML]="content.val | sanitizeHtml"></div>
        </div>
      </ng-container>
      <img id = "heroImage"  *ngIf="widgetOnHero && middle" [src]="clpHeroImage" class="hero-img-clp" alt="altName" [class.nestled]="nestled" />
    </div>

    <div class="flex-row-wrapper" [ngClass]="{'containsWidget': bottom}" #rowThree>
      <app-widget-wrapper *ngIf="bottom" [ngClass]="position === 'left' ? 'start' : 'end'"></app-widget-wrapper>
      <ng-container *ngFor="let content of clpContents?.bottom | mapToIterable">
        <div [style.width]="rowThreeWidth + '%'" *ngIf="content.val" class="item-wrapper" [ngClass]="position !== 'left' ? 'start' : 'end'" >
          <div class="flex-item" [innerHTML]="content.val | sanitizeHtml"></div>
        </div>
      </ng-container>
      <img id = "heroImage"  *ngIf="widgetOnHero && bottom" [src]="clpHeroImage" class="hero-img-clp" alt="altName" [class.nestled]="nestled" />
    </div>
  </ng-container>


  <!-- For Vertical -->
  <ng-container *ngIf="widgetOrientation === 'vertical'">

      <div class="flex-row-wrapper {{position}}" [ngClass]="{'containsWidget': top}" #rowOne>
        <app-widget-wrapper *ngIf="top" [ngClass]="position"></app-widget-wrapper>
        <ng-container *ngFor="let content of clpContents?.top | mapToIterable">
          <div [style.width]="rowOneWidth + '%'" *ngIf="content.val" class="item-wrapper" [ngClass]="position !== 'left' ? 'start' : 'end'">
            <div class="flex-item" [innerHTML]="content.val | sanitizeHtml"></div>
          </div>
        </ng-container>
        <img id = "heroImage"  *ngIf="widgetOnHero && top" [src]="clpHeroImage" class="hero-img-clp" alt="altName" [class.nestled]="nestled" />
      </div>
    
      <div class="flex-row-wrapper {{position}}" [ngClass]="{'containsWidget': middle}" #rowTwo>
        <app-widget-wrapper *ngIf="middle" [ngClass]="position"></app-widget-wrapper>
        <ng-container *ngFor="let content of clpContents?.middle | mapToIterable">
          <div [style.width]="rowTwoWidth + '%'" *ngIf="content.val" class="item-wrapper" [ngClass]="position !== 'left' ? 'start' : 'end'">
            <div class="flex-item" [innerHTML]="content.val | sanitizeHtml"></div>
          </div>
        </ng-container>
        <img id = "heroImage"  *ngIf="widgetOnHero && middle" [src]="clpHeroImage" class="hero-img-clp" alt="altName" [class.nestled]="nestled" />
      </div>
  
      <div class="flex-row-wrapper {{position}}" [ngClass]="{'containsWidget': bottom}" #rowThree>
        <app-widget-wrapper *ngIf="bottom" [ngClass]="position"></app-widget-wrapper>
        <ng-container *ngFor="let content of clpContents?.bottom | mapToIterable">
          <div [style.width]="rowThreeWidth + '%'" *ngIf="content.val" class="item-wrapper" [ngClass]="position !== 'left' ? 'start' : 'end'" >
            <div class="flex-item" [innerHTML]="content.val | sanitizeHtml"></div>
          </div>
        </ng-container>
        <img id = "heroImage"  *ngIf="widgetOnHero && bottom" [src]="clpHeroImage" class="hero-img-clp" alt="altName" [class.nestled]="nestled" />
      </div>
    </ng-container>
</section>
