import { InputField } from '../../shared/models';

export class TravelerInformation {
    ageIfNoBirthDate:  InputField<number | null>;
    firstName:  InputField<string>;
    lastName:  InputField<string>;
    formattedDateOfBirth: InputField<string>;

    constructor() {
        this.ageIfNoBirthDate = { value: null, isValid: true, isEditable: undefined, errorMessages: [] };
        this.firstName = { value: '', isValid: true, isEditable: undefined, errorMessages: [] };
        this.formattedDateOfBirth = { value: '', isValid: true, isEditable: undefined, errorMessages: [] };
        this.lastName = { value: '', isValid: true, isEditable: undefined, errorMessages: [] };
    }
}