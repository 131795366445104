import { UtilService } from './../../../shared/services/util.service';
import { ESitesNgbDateParserFormatter } from './../../services/date-parser-formatter';
import { QuoteService } from './../../services/quote.service';
import { State } from './../../../shared/models/state.interface';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators
} from '@angular/forms';
import {
  NgbInputDatepicker,
  NgbDateStruct,
  NgbDateParserFormatter
} from '@ng-bootstrap/ng-bootstrap';
import { ToolTipConstants } from '../../../shared/constants/tool-tip-constants';
import { AppStateService } from '../../../shared/services/app-state.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import * as AlertMessages from '../../../shared/constants/alert-messages.constants';
import { Store, Action } from '@ngrx/store';
import { QuoteState } from '../../reducers';
import { Actions, ofType } from '@ngrx/effects';
import { GetEventTicketQuote, GetSessionSeasonQuote } from '../../actions/generate-quote';
import { QuoteActionTypes } from '../../actions/quote';
import { DateRangeValidatorDirective } from '../../../shared/directives/validate-date-range.directive';
import * as moment from 'moment';
import { ButtonEnum } from '../../models/button.enum';
import { GoogleAnalyticsEventsService } from '../../../shared/services/google-analytics-events.service';
import { map } from 'rxjs/operators';
import { SessionSeasonFormData } from '../../models/form-data.interface';

@Component({
  selector: 'app-session-season',
  templateUrl: './session-season.component.html',
  styleUrls: ['./session-season.component.scss'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: ESitesNgbDateParserFormatter },
    ESitesNgbDateParserFormatter
  ]
})

export class SessionSeasonComponent implements OnInit {
  totalCostTooltipMessage: string = ToolTipConstants.nonTravelTotalCost;
  public sessionSeasonForm: FormGroup;
  public states: Array<State>;
  errors: String[] = [];
  buttonDisabled = false;
  partnerName: string;
  minDateTomorrow: Date;
  displayMonths: number;
  domain: string;
  /*
  * Configure the text of the submit button for the Get a Quote tab.
  */
  @Input() quoteButtonText: string;

  /*
  * Quote tab submit button background color.
  */
  @Input() quoteButtonColor: string;

  /*
  * Quote tab submit button background hover color.
  */
  @Input() quoteButtonHoverColor: string;

  /*
  * ID of the component
  */
  @Input() componentId: string;

  /*
  * Range date picker
  */
  @ViewChild('rangeDatePicker') rangeDatePicker;

  constructor(
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private _dateFormatter: ESitesNgbDateParserFormatter,
    private quoteService: QuoteService,
    private appStateService: AppStateService,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<QuoteState>,
    private action$: Actions,
    private ga: GoogleAnalyticsEventsService
  ) {
    this.sessionSeasonForm = this.formBuilder.group({
      state: ['', Validators.required],
      cost: new FormControl('', [
        Validators.required,
        Validators.pattern(/^(\d+|\d+\.\d{1,2})$/)
      ]),
      numberOfPasses: ['', Validators.required],
      beginEndDate: ['']
    });
  }

  get cost() {
    return this.sessionSeasonForm.get('cost');
  }
  get state() {
    return this.sessionSeasonForm.get('state');
  }
  get numberOfPasses() {
    return this.sessionSeasonForm.get('numberOfPasses');
  }
  get today(): NgbDateStruct {
    const now = new Date();
    return {
      year: now.getFullYear(),
      month: now.getMonth() + 1,
      day: now.getDate()
    };
  }

  get beginEndDate() {
    return this.sessionSeasonForm.get('beginEndDate');
  }

  ngOnInit() {
    this.utilService.getStates().subscribe(states => {
      this.states = states;
    });

    this.partnerName = this.route.snapshot.params['partner'];

    this.domain = this.appStateService.getPartnerConfig().domain;

    const sessionQuoteForm = this.appStateService.getQuoteForm() as SessionSeasonFormData;

    if (typeof sessionQuoteForm !== 'undefined' && sessionQuoteForm !== null) {
      // Session Storage stores all values as string. The Departure and Return date will need to be convert it to
      // Date objects before the calendar can parse it.
      const beginEndDate = [];
      // Departure Date
      beginEndDate.push(new Date(sessionQuoteForm.beginDate));
      // Return Date
      beginEndDate.push(new Date(sessionQuoteForm.endDate));

      this.sessionSeasonForm.patchValue({
        beginEndDate: beginEndDate,
        numberOfPasses: sessionQuoteForm.numberOfPasses,
        state: sessionQuoteForm.state,
        cost: sessionQuoteForm.cost
      });
    }

    this.tomorrow();

    this.displayMonths = window.innerWidth >= 768
      ? 2
      : 1;
  }

  public onSubmit() {
    this.setDateValidation();

    this.errors = [];
    if (this.sessionSeasonForm.valid) {
      this.buttonDisabled = true;
      const quoteFormData : SessionSeasonFormData = {
        state: this.state.value,
        cost: this.cost.value as number,
        numberOfPasses: this.numberOfPasses.value as number,
        beginDate: moment(this.beginEndDate.value[0]).format('MM/DD/YYYY'),
        endDate: moment(this.beginEndDate.value[1]).format('MM/DD/YYYY'),
      }
      this.store.dispatch(
        new GetSessionSeasonQuote(quoteFormData)
      );

      this.action$.pipe(
        ofType<any>(QuoteActionTypes.GetSessionSeasonQuoteSuccess),
        map(action => action.payload)
      ).subscribe(q => {
        this.buttonDisabled = false;
        this.ga.formSubmit(ButtonEnum.GetQuote);
        this.appStateService.clearManagePolicyState();
        this.appStateService.clearPurchaseState();
        this.appStateService.setPurchaseSessionActive(true);
        this.appStateService.setQuoteForm(quoteFormData);
        this.appStateService.setQuote(q);
        this.appStateService.broadcastQuote(q);
        this.router.navigateByUrl(`${this.partnerName}/policy/products`);
      });

        this.action$.pipe(
          ofType<any>(QuoteActionTypes.GetSessionSeasonQuoteFailure),
          map(action => action.payload)
        ).subscribe((err: HttpErrorResponse) => {
          if (err.status === 400) {
            this.errors = this.utilService.getModelStateErrors(
              err.error.modelState
            );
          }
          if (this.errors.length === 0) {
            this.errors.push(AlertMessages.SERVICE_DOWN);
          }
          this.buttonDisabled = false;
        });
    } else {
      this.utilService.validateAllFormFields(this.sessionSeasonForm);
    }
  }

  onClose() {
    this.setDateValidation();
    this.utilService.closeModal(this.rangeDatePicker);
  }

  onSelect() {
    // Close datepicker if end date is selected.
    if (this.rangeDatePicker.value[1]) {
      this.rangeDatePicker.overlayVisible = false;
    }
  }

  setDateValidation() {
    if (!this.rangeDatePicker.value[0]) {
      this.sessionSeasonForm.controls['beginEndDate'].setErrors(Validators.required);
    }
  }

  public reset() {
    this.sessionSeasonForm.reset();
  }

  tomorrow() {
    const d = new Date();
    d.setDate(d.getDate() + 1);
    this.minDateTomorrow = d;
  }
}
