import { Injectable } from '@angular/core';
import { Transaction } from '../models/index';
import { DomainTypeEnum } from '../../policy/models/domain-type.enum';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { ButtonEnum } from '../../quote/models/button.enum';
declare let dataLayer: any;

@Injectable()
export class GoogleAnalyticsEventsService {

  constructor(private router: Router) { }

  push(obj: object) {
    dataLayer.push(obj);
  }

  completeTransaction(trans: Transaction) {

    let productsGA4 = [];

    for (let p of trans.transactionProducts) {
      productsGA4.push({
        'item_id': `${p.sku}`,
        'item_name': `${p.name}`,
        'affiliation': `${trans.transactionAffiliation}`,
        'item_category': `${p.category}`,
        'price': p.price,
        'quantity': p.quantity
      });
    }

    dataLayer.push({
      'event':'transaction-complete',
      'currency': 'USD',
      'value': trans.transactionTotal,
      'ecommerce': {
        'tax': trans.transactionTax,
        'shipping': trans.transactionShipping,
        'transaction_id':`${trans.transactionId}`,
        'items': productsGA4
      }
    });
  }

  formSubmit(formName: string) {
    if(this.router.url.includes('/policy/products')){
      dataLayer.push({
        'event': 'formSubmitted',
        'formName': ButtonEnum.UpdateQuote
      });
    }
    else {
      dataLayer.push({
        'event': 'formSubmitted',
        'formName': formName
      });
    }
    
  }

  buttonClick(buttonName: string) {
    dataLayer.push({
      'event': 'button_click',
      'button_id': buttonName
    });
  }

  // Set container ID based on domain
  setGaContainerID(domain: string) {
    switch (domain) {
      case(DomainTypeEnum.Travel):
        return `${environment.etravelGoogleTag}`;
      case(DomainTypeEnum.EventTicketProtection):
        return `${environment.eventTicketProtectionGoogleTag}`;  
      case(DomainTypeEnum.EventRegistration):
        return `${environment.eventRegistrationGoogleTag}`;
      case(DomainTypeEnum.Season):
        return `${environment.seasonDomainGoogleTag}`;
      case(DomainTypeEnum.Session):
        return `${environment.sessionDomainGoogleTag}`;
    }
  }
}