<mat-card>
  <h1>Contact Us</h1>
  <form [formGroup]="contactUsForm" (ngSubmit)="onSubmit()" class="form" novalidate>

    <app-telephone [phoneNumber]="phoneNumber"></app-telephone>
    <div class="form-row">
      <div class="col">
        <div class="form-group">
          <label for="name">Name</label>
          <input type="text" id="name" class="form-control form-control-sm" formControlName="name" />
          <app-input-validation [inputFormControl]="name"></app-input-validation>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="col">
        <div class="form-group">
          <label for="email">Email</label>
          <input type="text" id="email" class="form-control form-control-sm" formControlName="email" />
          <app-input-validation [inputFormControl]="email"></app-input-validation>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="col">
        <div class="form-group">
          <label for="policyNumber">Policy/Claim #
            <small class="text-muted">
              Optional
            </small>
          </label>
          <input type="text" id="policyNumber" class="form-control form-control-sm" formControlName="policyNumber" />
          <app-input-validation [inputFormControl]="policyNumber"></app-input-validation>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="col">
        <div class="form-group">
          <label for="subject">Subject</label>
          <select id="subject" class="form-control form-control-sm" formControlName="subject">
            <option value="" selected>Select a subject</option>
            <option *ngFor="let opt of subjectSelections | async" [ngValue]="opt.value">{{opt.key}}</option>
          </select>
          <app-input-validation [inputFormControl]="subject"></app-input-validation>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="col">
        <div class="form-group">
          <label for="question">Question</label>
          <textarea id="question" class="form-control form-control-sm" formControlName="question" maxlength="500"></textarea>
          <app-input-validation [inputFormControl]="question"></app-input-validation>
        </div>
      </div>
    </div>

    <div class="form-row submit">
      <div class="col">
        <button

        (mouseover)="hovering = true" (mouseout)="hovering = false" [style.background-color]="hovering === true ? secondaryButtonColor : primaryButtonColor" mat-raised-button [disabled]="buttonDisabled" type="submit" color="primary">
          Submit
        </button>
      </div>
    </div>

  </form>
</mat-card>