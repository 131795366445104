import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms/src/model';
import { ErrorMessage, FormValidationResult } from '../models/index';

@Injectable()
export class ValidationService {

  constructor() { }

  validateFormControls(modelControlPairs: Array<[{ isValid: boolean, errorMessages: Array<ErrorMessage> }, AbstractControl]>): FormValidationResult {
    let validationResult = new FormValidationResult();

    for (let pair of modelControlPairs) {
      validationResult.errorMessages = validationResult.errorMessages.concat(this.validateFormControl(pair[0], pair[1]));
    }

    return validationResult;
  }

  validateFormControl(model: { isValid: boolean, errorMessages: Array<ErrorMessage> }, control: AbstractControl): Array<ErrorMessage> {
    if (model && !model.isValid) {
      control.setErrors({
        'amoError': true
      });

      control.markAsTouched();

      return model.errorMessages;
    }

    return [];
  }

}
