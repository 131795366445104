import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { UtilService } from '../../../shared/services/util.service';
import { SelectListItem } from '../../../shared/models';
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators
} from '@angular/forms';
import { map } from 'rxjs/operators';


import { Router, ActivatedRoute } from '@angular/router';
import { AppStateService } from '../../../shared/services/app-state.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ESitesNgbDateParserFormatter } from '../../services/date-parser-formatter';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import * as AlertMessages from '../../../shared/constants/alert-messages.constants';
import { ToolTipConstants } from '../../../shared/constants/tool-tip-constants';
import { Store } from '@ngrx/store';
import { QuoteState } from '../../reducers';
import {
  GetCarRentalQuote,
} from '../../actions/generate-quote';
import { QuoteActionTypes } from '../../actions/quote';
import { Actions, ofType } from '@ngrx/effects';
import * as moment from 'moment';
import { ButtonEnum } from '../../models/button.enum';
import { GoogleAnalyticsEventsService } from '../../../shared/services/google-analytics-events.service';
import { CarRentalFormData } from '../../models/form-data.interface';

@Component({
  selector: 'app-car-rental',
  templateUrl: './car-rental.component.html',
  styleUrls: ['./car-rental.component.scss'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: ESitesNgbDateParserFormatter },
    ESitesNgbDateParserFormatter
  ]
})
export class CarRentalComponent implements OnInit {
  carRentalForm: FormGroup;
  states: SelectListItem[];
  errors: String[] = [];
  buttonDisabled = false;
  partnerName: string;
  rentalCostTooltipMessage: string = ToolTipConstants.tripId;
  minDate: Date;
  displayMonths: number;

  @Input() quoteButtonText;
  @Input() quoteButtonColor;
  @Input() quoteButtonHoverColor;
  @Input() componentId: string;
  @ViewChild('rangeDatePicker') rangeDatePicker;

  constructor(
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private router: Router,
    private route: ActivatedRoute,
    private appStateService: AppStateService,
    private store: Store<QuoteState>,
    private action$: Actions,
    private ga: GoogleAnalyticsEventsService
  ) {
    this.carRentalForm = this.formBuilder.group({
      pickupDropOffDate: [''],
      totalTripCost: new FormControl('', [
        Validators.required,
        Validators.pattern(/^(\d+|\d+\.\d{1,2})$/)
      ]),
      state: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.utilService.getStates().subscribe(states => {
      this.states = states;
    });

    this.partnerName = this.route.snapshot.params['partner'];

    const sessionQuoteForm = this.appStateService.getQuoteForm() as CarRentalFormData;

    if (typeof sessionQuoteForm !== 'undefined' && sessionQuoteForm !== null) {
      // Session Storage stores all values as string. The Departure and Return date will need to be convert it to
      // Date objects before the calendar can parse it.
      const pickupDropOffDate = [];
      // Departure Date
      pickupDropOffDate.push(new Date(sessionQuoteForm.departureDate));
      // Return Date
      pickupDropOffDate.push(new Date(sessionQuoteForm.returnDate));

      this.carRentalForm.patchValue({
        pickupDropOffDate: pickupDropOffDate,
        totalTripCost: sessionQuoteForm.cost,
        state: sessionQuoteForm.state
      });
    }

    this.displayMonths = window.innerWidth >= 768
      ? 2
      : 1;

    this.setMinDate();
  }

  onClose() {
    this.setDateValidation();
    this.utilService.closeModal(this.rangeDatePicker);
  }

  setDateValidation() {
    if (!this.rangeDatePicker.value[0]) {
      this.carRentalForm.controls['pickupDropOffDate'].setErrors(Validators.required);
    }
  }

  onSelect() {
    // Close datepicker if end date is selected.
    if (this.rangeDatePicker.value[1]) {
      this.rangeDatePicker.overlayVisible = false;
    }
  }

  onSubmit() {
    this.errors = [];
    this.setDateValidation();

    if (this.carRentalForm.valid) {
      this.buttonDisabled = true;
      const quoteFormData : CarRentalFormData = {
        state: this.state.value,
        cost: this.totalTripCost.value as number,
        departureDate: moment(this.pickupDropOffDate.value[0]).format('MM/DD/YYYY'),
        returnDate: moment(this.pickupDropOffDate.value[1]).format('MM/DD/YYYY')
      }
      this.store.dispatch(
        new GetCarRentalQuote(quoteFormData)
      );

      this.action$.pipe(
        ofType<any>(QuoteActionTypes.GetCarRentalQuoteSuccess),
        map(action => action.payload)
      ).subscribe(q => {
        this.buttonDisabled = false;
        this.ga.formSubmit(ButtonEnum.GetQuote);
        this.appStateService.clearManagePolicyState();
        this.appStateService.clearPurchaseState();
        this.appStateService.setPurchaseSessionActive(true);
        this.appStateService.setQuoteForm(quoteFormData);
        this.appStateService.setQuote(q);
        this.appStateService.broadcastQuote(q);
        this.router.navigateByUrl(`${this.partnerName}/policy/products`);
      });

      this.action$.pipe(
        ofType<any>(QuoteActionTypes.GetCarRentalQuoteFailure),
        map(action => action.payload)
      ).subscribe((err: HttpErrorResponse) => {
          if (err.status === 400) {
            this.errors = this.utilService.getModelStateErrors(
              err.error.modelState
            );
          }
          if (this.errors.length === 0) {
            this.errors.push(AlertMessages.SERVICE_DOWN);
          }
          this.buttonDisabled = false;
        });
    } else {
      this.utilService.validateAllFormFields(this.carRentalForm);
    }
  }


  setMinDate() {
    const d = new Date();
    d.setDate(d.getDate());
    this.minDate = d;
  }

  get totalTripCost() { return this.carRentalForm.get('totalTripCost'); }
  get state() { return this.carRentalForm.get('state'); }
  get pickupDropOffDate() {
    return this.carRentalForm.get('pickupDropOffDate');
  }
}
