
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, tap} from 'rxjs/operators';
import { AlertService } from './../alert/services/alert.service';
import { LoadingService } from './loading.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Injector } from '@angular/core';

import { Store } from '@ngrx/store';
import { Alert } from '../../shared/actions/alert';
import { AlertState } from '../../shared/reducers';
import { AlertTypeEnum } from '../../shared/alert/alert-type.enum';

export abstract class NetworkService {

    protected loadingService: LoadingService;
    protected alertService: AlertService;
    protected store: Store<AlertState>;

    constructor(
        injector: Injector
    ) {
        this.loadingService = injector.get(LoadingService);
        this.alertService = injector.get(AlertService);
    }

    request(httpObs: Observable<any>): Observable<any> {
        this.loadingService.open();
        return httpObs.pipe(
            tap(() => {
                this.loadingService.close();
            }),
            catchError(e => this.handleError(e)),);
    }

    handleError(err: HttpErrorResponse) {
        if (err.error instanceof Error) {
            // A client-side or network error occurred. Handle it accordingly.
            console.log('An error occurred:', err.error.message);
        } else {
            if (err.status === 500) {
                this.store.dispatch(
                    new Alert({
                      message: 'An internal error has occured.  Please reload the page and try again.',
                      alertType: AlertTypeEnum.ERROR
                    })
                  );
            }
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.log(`Backend returned code ${err.status}, body was: ${err.error}`);
        }

        if (this.loadingService) this.loadingService.close();

        return observableThrowError(err);
    }
}
