import { Action } from '@ngrx/store';
import { createActionType } from '../../shared/utils';
import { Quote } from '../models/quote.interface';

export enum QuoteActionTypes {
  GetCarRentalQuoteSuccess = '[Quote] Car Rental Success',
  GetCarRentalQuoteFailure = '[Quote] Car Rental Failure',
  GetTravelQuoteSuccess = '[Quote] Non Age Rated Travel Success',
  GetTravelQuoteFailure = '[Quote] Non Age Rated Travel Failure',
  GetTravelAgeRatedQuoteSuccess = '[Quote] Age Rated Travel Success',
  GetTravelAgeRatedQuoteFailure = '[Quote] Age Rated Travel Failure',
  GetEventTicketQuoteSuccess = '[Quote] Event Ticket Success',
  GetEventTicketQuoteFailure = '[Quote] Event Ticket Failure',
  GetAnnualQuoteSuccess = '[Quote] Annual Quote Success',
  GetAnnualQuoteFailure = '[Quote] Annual Quote Failure',
  GetRailQuoteSuccess = '[Quote] Rail Quote Success',
  GetRailQuoteFailure = '[Quote] Rail Quote Failure',
  GetSessionSeasonQuoteSuccess = '[Quote] Session and Season Quote Success',
  GetSessionSeasonQuoteFailure = '[Quote] Session and Season Quote Failure'
}

export class GetCarRentalQuoteSuccess implements Action {
  readonly type = QuoteActionTypes.GetCarRentalQuoteSuccess;

  constructor(public payload: Quote) {}
}

export class GetCarRentalQuoteFailure implements Action {
  readonly type = QuoteActionTypes.GetCarRentalQuoteFailure;

  constructor(public payload: any) {}
}

export class GetTravelQuoteSuccess implements Action {
  readonly type = QuoteActionTypes.GetTravelQuoteSuccess;

  constructor(public payload: Quote) {}
}

export class GetTravelQuoteFailure implements Action {
  readonly type = QuoteActionTypes.GetTravelQuoteFailure;

  constructor(public payload: any) {}
}

export class GetTravelAgeRatedQuoteSuccess implements Action {
  readonly type = QuoteActionTypes.GetTravelAgeRatedQuoteSuccess;

  constructor(public payload: Quote) {}
}

export class GetTravelAgeRatedQuoteFailure implements Action {
  readonly type = QuoteActionTypes.GetTravelAgeRatedQuoteFailure;

  constructor(public payload: any) {}
}

export class GetEventTicketQuoteSuccess implements Action {
  readonly type = QuoteActionTypes.GetEventTicketQuoteSuccess;

  constructor(public payload: Quote) {}
}

export class GetEventTicketQuoteFailure implements Action {
  readonly type = QuoteActionTypes.GetEventTicketQuoteFailure;

  constructor(public payload: any) {}
}

export class GetAnnualQuoteSuccess implements Action {
  readonly type = QuoteActionTypes.GetAnnualQuoteSuccess;

  constructor(public payload: Quote) {}
}

export class GetAnnualQuoteFailure implements Action {
  readonly type = QuoteActionTypes.GetAnnualQuoteFailure;

  constructor(public payload: any) {}
}

export class GetRailQuoteSuccess implements Action {
  readonly type = QuoteActionTypes.GetRailQuoteSuccess;

  constructor(public payload: Quote) {}
}

export class GetRailQuoteFailure implements Action {
  readonly type = QuoteActionTypes.GetRailQuoteFailure;

  constructor(public payload: any) {}
}

export class GetSessionSeasonQuoteSuccess implements Action {
  readonly type = QuoteActionTypes.GetSessionSeasonQuoteSuccess;

  constructor(public payload: Quote) {}
}

export class GetSessionSeasonQuoteFailure implements Action {
  readonly type = QuoteActionTypes.GetSessionSeasonQuoteFailure;

  constructor(public payload: any) {}
}

export type QuoteActionUnion =
  | GetCarRentalQuoteSuccess
  | GetCarRentalQuoteFailure
  | GetTravelQuoteSuccess
  | GetTravelQuoteFailure
  | GetTravelAgeRatedQuoteSuccess
  | GetTravelAgeRatedQuoteFailure
  | GetEventTicketQuoteSuccess
  | GetEventTicketQuoteFailure
  | GetAnnualQuoteSuccess
  | GetAnnualQuoteFailure
  | GetRailQuoteSuccess
  | GetRailQuoteFailure
  | GetSessionSeasonQuoteSuccess
  | GetSessionSeasonQuoteFailure;
