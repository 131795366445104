<app-widget id="widget" class="col-md-8"
[showQuoteTab]="showQuoteTab"
[showPolicyTab]="showPolicyTab"
[showClaimTab]="showClaimTab"

[tabHoverColor]="tabHoverColor"
[quoteButtonText]="quoteButtonText"
[quoteButtonColor]="quoteButtonColor"
[quoteButtonHoverColor]="quoteButtonHoverColor"
[quoteWidgetSelection]="quoteWidgetSelection"
[destinationSelection]="destinationSelection"
[quoteTabCaption]="quoteTabCaption"
></app-widget>