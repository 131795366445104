export function dateOfBirthToAge(dateOfBirth: Date): number {
    const today = new Date();
    const m = today.getMonth() - dateOfBirth.getMonth();
    let age = today.getFullYear() - dateOfBirth.getFullYear();

    if (m < 0 || (m === 0 && today.getDate() < dateOfBirth.getDate())) {
        age--;
    }

    return age;
}