import { ConfirmationDialogComponent } from '../components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { AlertService } from './services/alert.service';
import { Component, OnInit, Input } from '@angular/core';
import { AlertTypeEnum } from './alert-type.enum';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import * as fromAlert from '../reducers';
import { Actions, ofType } from '@ngrx/effects';
import { AlertActionTypes } from '../actions/alert';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

  private _message: string;
  private _alertType: AlertTypeEnum;
  message$ = this.store.pipe(select(fromAlert.getMessage));
  alertType$ = this.store.pipe(select(fromAlert.getAlertType));

  public show = false;
  @Input() duration: number = 20000;

  get message(): string { return this._message; }
  get alertType(): AlertTypeEnum { return this._alertType; }

  constructor(
    private alertService: AlertService,
    private router: Router,
    private store: Store<fromAlert.State>,
    private action$: Actions
  ) { }

  ngOnInit() {

    this.action$.pipe(
      ofType(AlertActionTypes.Alert)
    )
    .subscribe(() => {
      this.show = true;
      setTimeout(() => {
        this.show = false;
      }, this.duration);
    });


    this.router.events.subscribe(event => {
      this.show = false;
    });
  }

  private showMessage(message: string, type: AlertTypeEnum) {
    this._message = message;
  }

  closeAlert(alert) {
    this.show = false;
    this._message = '';
  }
}
