import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable ,  of } from 'rxjs';
import { map, switchMap, mergeMap, catchError } from 'rxjs/operators';
import { QuoteService } from '../services/quote.service';

import {
  GetCarRentalQuote,
  GetTravelQuote,
  GenerateQuoteActionTypes,
  GetTravelAgeRatedQuote,
  GetEventTicketQuote,
  GetAnnualTravelQuote,
  GetRailQuote,
  GetSessionSeasonQuote
} from '../actions/generate-quote';

import {
  GetCarRentalQuoteSuccess,
  GetCarRentalQuoteFailure,
  GetTravelQuoteSuccess,
  GetTravelQuoteFailure,
  GetTravelAgeRatedQuoteSuccess,
  GetTravelAgeRatedQuoteFailure,
  GetEventTicketQuoteSuccess,
  GetEventTicketQuoteFailure,
  GetAnnualQuoteFailure,
  GetAnnualQuoteSuccess,
  GetRailQuoteSuccess,
  GetRailQuoteFailure,
  GetSessionSeasonQuoteFailure,
  GetSessionSeasonQuoteSuccess
} from '../actions/quote';

@Injectable()
export class GenerateQuoteEffects {
  @Effect()
  getCarRentalQuote$: Observable<Action> = this.actions$.pipe(
    ofType<GetCarRentalQuote>(GenerateQuoteActionTypes.GetCarRentalQuote)
  )
    .pipe(
      switchMap(action =>
        this.quoteService.getCarRentalQuote(
          action.payload.state,
          action.payload.cost,
          action.payload.departureDate,
          action.payload.returnDate
        )
        .pipe(
          map(quote => new GetCarRentalQuoteSuccess(quote)),
          catchError(error => of(new GetCarRentalQuoteFailure(error)))
        )
      )
    );

    @Effect()
    getTravelQuote$: Observable<Action> = this.actions$.pipe(
      ofType<GetTravelQuote>(GenerateQuoteActionTypes.GetTravelQuote)
    )
    .pipe(
      switchMap(action =>
        this.quoteService.getTravelQuote(
          action.payload.state,
          action.payload.cost,
          action.payload.destination,
          action.payload.departureDate,
          action.payload.returnDate,
          action.payload.numTravelers,
          action.payload.frequentFlyer
        )
        .pipe(
          map(quote => new GetTravelQuoteSuccess(quote)),
          catchError(error => of(new GetTravelQuoteFailure(error)))
        )
      )
    );

    @Effect()
    getTravelAgeRatedQuote$: Observable<Action> = this.actions$.pipe(
      ofType<GetTravelAgeRatedQuote>(GenerateQuoteActionTypes.GetTravelAgeRatedQuote)
    )
    .pipe(
      switchMap(action =>
        this.quoteService.getTravelAgeRatedQuote(
          action.payload.state,
          action.payload.cost,
          action.payload.destination,
          action.payload.departureDate,
          action.payload.returnDate,
          action.payload.depositDate,
          action.payload.age,
          action.payload.ageOrDob
        )
        .pipe(
          map(quote => new GetTravelAgeRatedQuoteSuccess(quote)),
          catchError(error => of(new GetTravelAgeRatedQuoteFailure(error)))
        )
      )
    );

    @Effect()
    getEventTicketQuote$: Observable<Action> = this.actions$.pipe(
      ofType<GetEventTicketQuote>(GenerateQuoteActionTypes.GetEventTicketQuote)
    )
    .pipe(
      switchMap(action =>
        this.quoteService.getEventTicketQuote(
          action.payload.state,
          action.payload.cost,
          action.payload.numberOfTickets,
          action.payload.eventBeginDate,
          action.payload.eventEndDate,
        )
        .pipe(
          map((quote) => new GetEventTicketQuoteSuccess(quote)),
          catchError(error => of(new GetEventTicketQuoteFailure(error)))
        )
      )
    );

    @Effect()
    getAnnualQuote$: Observable<Action> = this.actions$.pipe(
      ofType<GetAnnualTravelQuote>(GenerateQuoteActionTypes.GetAnnualTravelQuote)
    )
    .pipe(
      switchMap(action =>
        this.quoteService.getAnnualQuote(
          action.payload.state,
          action.payload.numTravelers,
          action.payload.destination
        )
        .pipe(
          map((quote) => new GetAnnualQuoteSuccess(quote)),
          catchError(error => of(new GetAnnualQuoteFailure(error)))
        )
      )
    );

    @Effect()
    getRailQuote$: Observable<Action> = this.actions$.pipe(
      ofType<GetRailQuote>(GenerateQuoteActionTypes.GetRailQuote)
    )
    .pipe(
      switchMap(action =>
        this.quoteService.getRailQuote(
          action.payload.state,
          action.payload.totalTripCost,
          action.payload.departureDate,
          action.payload.returnDate,
          action.payload.numTravelers,
        )
        .pipe(
          map((quote) => new GetRailQuoteSuccess(quote)),
          catchError(error => of(new GetRailQuoteFailure(error)))
        )
      )
    );

    @Effect()
    getSessionSeasonQuote$: Observable<Action> = this.actions$.pipe(
      ofType<GetSessionSeasonQuote>(GenerateQuoteActionTypes.GetSessionSeasonQuote)
    )
    .pipe(
      switchMap(action =>
        this.quoteService.getEventTicketQuote(
          action.payload.state,
          action.payload.cost,
          action.payload.numberOfPasses,
          action.payload.beginDate,
          action.payload.endDate,
        )
        .pipe(
          map((quote) => new GetSessionSeasonQuoteSuccess(quote)),
          catchError(error => of(new GetSessionSeasonQuoteFailure(error)))
        )
      )
    );

  constructor(private actions$: Actions, private quoteService: QuoteService) {}
}
