import { Policy } from  '../../policy/models';

export class PolicyConfirmation {
    type: PolicyConfirmationTypeEnum;
    policy: Policy;

    constructor(type: PolicyConfirmationTypeEnum, policy: Policy) {
        this.type = type;
        this.policy = policy;
    }
}

export enum PolicyConfirmationTypeEnum {
    Modify = 1,
    Purchase,
    Cancel
}