import { Action } from '@ngrx/store';
import { createActionType } from '../../shared/utils';
import { Report } from '../models';

export enum ReportActionTypes {
  EmailQuoteSuccess = '[Report] Quote Success',
  EmailQuoteFailure = '[Report] Quote Failure',
  EmailReceiptSuccess = '[Report] Receipt Success',
  EmailReceiptFailure = '[Report] Receipt Failure'
}

export class EmailQuoteSuccess implements Action {
  readonly type = ReportActionTypes.EmailQuoteSuccess;

  constructor(public payload: Report) {}
}

export class EmailQuoteFailure implements Action {
  readonly type = ReportActionTypes.EmailQuoteFailure;

  constructor(public payload: any) {}
}

export class EmailReceiptSuccess implements Action {
  readonly type = ReportActionTypes.EmailReceiptSuccess;

  constructor(public payload: Report) {}
}

export class EmailReceiptFailure implements Action {
  readonly type = ReportActionTypes.EmailReceiptFailure;

  constructor(public payload: any) {}
}

export type ReportActionUnion =
  | EmailQuoteSuccess
  | EmailQuoteFailure
  | EmailReceiptSuccess
  | EmailReceiptFailure;
