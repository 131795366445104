import { GenerateQuoteActionTypes, GeneratesQuoteActionUnion } from './../actions/generate-quote';
import { QuoteComponentEnum } from '../models/quote-component.enum';
import { createSelector } from '@ngrx/store';

export interface State {
  stateResidence: string;
  age?: number[];
  dateOfBirth?: string[];
  cost: number;
  destination?: string;
  departureDate?: string;
  returnDate?: string;
  initialDepositDate?: string;
  numberOfTravelers?: number;
  frequentFlyer?: boolean;
  type: QuoteComponentEnum;
  eventBeginDate?: string;
  eventEndDate?: string;
  numberOfTickets?: number;
  numberOfPasses?: number;
}

const initialState: State = {
  stateResidence: '',
  age: [],
  dateOfBirth: [],
  cost: null,
  destination: '',
  departureDate: '',
  returnDate: '',
  initialDepositDate: '',
  numberOfTravelers: 1,
  frequentFlyer: false,
  type: QuoteComponentEnum.None,
  eventBeginDate: '',
  eventEndDate: '',
  numberOfTickets: 0
};

export function reducer(state = initialState, action: GeneratesQuoteActionUnion): State {
  switch (action.type) {
    case GenerateQuoteActionTypes.GetCarRentalQuote: {
      return {
        ...state,
        stateResidence: action.payload.state,
        departureDate: action.payload.departureDate,
        returnDate: action.payload.returnDate,
        type: QuoteComponentEnum.CarRental
      };
    }

    case GenerateQuoteActionTypes.GetTravelQuote: {
      return {
        ...state,
        stateResidence: action.payload.state,
        departureDate: action.payload.departureDate,
        returnDate: action.payload.returnDate,
        type: QuoteComponentEnum.TravelNonAgeRated,
        destination: action.payload.destination,
        numberOfTravelers: action.payload.numTravelers,
        frequentFlyer: action.payload.frequentFlyer,
        cost: action.payload.cost,
      };
    }

    case GenerateQuoteActionTypes.GetTravelAgeRatedQuote: {
      return {
        ...state,
        stateResidence: action.payload.state,
        departureDate: action.payload.departureDate,
        returnDate: action.payload.returnDate,
        destination: action.payload.destination,
        cost: action.payload.cost,
        initialDepositDate: action.payload.depositDate,
        age: action.payload.age,
        type: QuoteComponentEnum.TravelAgeRated
      };
    }

    case GenerateQuoteActionTypes.GetEventTicketQuote: {
      return {
        ...state,
        stateResidence: action.payload.state,
        cost: action.payload.cost,
        numberOfTickets: action.payload.numberOfTickets,
        eventBeginDate: action.payload.eventBeginDate,
        eventEndDate: action.payload.eventEndDate,
        type: QuoteComponentEnum.EventTicket
      };
    }

    case GenerateQuoteActionTypes.GetAnnualTravelQuote: {
      return {
        ...state,
        stateResidence: action.payload.state,
        destination: action.payload.destination,
        type: QuoteComponentEnum.Annual
      };
    }

    case GenerateQuoteActionTypes.GetRailQuote: {
      return {
        ...state,
        stateResidence: action.payload.state,
        cost: action.payload.totalTripCost,
        numberOfTickets: action.payload.numTravelers,
        departureDate: action.payload.departureDate,
        returnDate: action.payload.returnDate,
        type: QuoteComponentEnum.Rail
      };
    }

    case GenerateQuoteActionTypes.GetSessionSeasonQuote: {
      return {
        ...state,
        stateResidence: action.payload.state,
        cost: action.payload.cost,
        numberOfPasses: action.payload.numberOfPasses,
        departureDate: action.payload.beginDate,
        returnDate: action.payload.endDate,
        type: QuoteComponentEnum.SessionSeason
      };
    }

    default: {
      return state;
    }
  }
}
