
import {of as observableOf,  Observable } from 'rxjs';

import {tap, map, retry, catchError} from 'rxjs/operators';
import { State } from './../../shared/models/state.interface';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { environment } from '../../../environments/environment';
import { NetworkService } from './network.service';
import { SelectListItem, InputField } from '../models/index';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct';
import { AppStateService } from './app-state.service';

import { Router } from '@angular/router';
import { Quote } from '../../quote/models/quote.interface';
import * as moment from 'moment';
import { SupplierInfo } from '../../policy/models/supplier-info.interface';

@Injectable()
export class UtilService extends NetworkService {

  private AUTH_HEADER = 'token';

  private _suppliers: Array<SupplierInfo>;

  constructor(
    private http: HttpClient,
    injector: Injector,
    private appStateService: AppStateService,
    private router: Router
  ) { super(injector); }

  public validateAllFormFields(formGroup: FormGroup | FormArray) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      } else if (control instanceof FormArray) {
        this.validateAllFormFields(control);
      }
    });
  }

  getToken(accam: string): Observable<string> {
    const headers = new HttpHeaders().set('shouldAuth', 'false');
    return this.http.get<any>(`${environment.apiGatewayUrl}/agency/${accam}`, { observe: 'response', headers: headers }).pipe(
    map(resp => {
        const token = resp.headers.get(this.AUTH_HEADER);
        if (!resp.body.isValid || token === null || token === 'null') {
          throw new Error('Token cannot be pulled');
        }
        return token;
      }),
      retry(2),
      catchError( (err) => {
        window.location.href = '/errors/maintenance.html';
        return observableOf(err);
      }), );
  }

  public getStates(): Observable<Array<State>> {
    const states = this.appStateService.getStates();
    if (states && states.length > 0) {
      return observableOf(states);
    } else {
      const headers = new HttpHeaders().set('shouldAuth', 'false');

      return this.http.get<Array<State>>(`${environment.apiGatewayUrl}/state`, { headers: headers }).pipe(
        tap(states => this.appStateService.setStates(states)),
        catchError(this.handleError), );
    }
  }

  public scrollTo(element: string) {
    try {
      document.querySelector(element).scrollIntoView();
    } catch (e) {

    }
  }

  public getDestinations(): Observable<Array<SelectListItem>> {
    const destinations = this.appStateService.getDestinations();
    if (destinations && destinations.length > 0) {
      return observableOf(destinations);
    } else {
      return this.http.get<Array<SelectListItem>>(`${environment.apiGatewayUrl}/destination`).pipe(
        tap(destinations => this.appStateService.setDestinations(destinations)),
        catchError(this.handleError), );
    }
  }

  public getGlobalBenefitsList(): Observable<Quote> {
    const booking = this.appStateService.getQuote().quote;
    this.appStateService.clearGlobalBenefitsList();
    return this.http.put<Quote>(`${environment.apiGatewayUrl}/quote/benefits`, booking).pipe(
      tap(res => this.appStateService.setGlobalBenefitsList(res)),
      catchError(this.handleError), );
  }

  public getSuppliers(): Observable<Array<SupplierInfo>> {
    return this._getSuppliers();
  }

  private _getSuppliers(): Observable<Array<SupplierInfo>> {
    if (typeof this._suppliers !== 'undefined' && this._suppliers.length > 0) {
      return observableOf(this._suppliers);
    } else {
      return this.http.get<Array<SupplierInfo>>(`${environment.apiGatewayUrl}/supplier`).pipe(
        tap(suppliers => this._suppliers = suppliers),
        catchError(this.handleError), );
    }
  }

  public closeModal(rangeDatePicker) {
    // Check to see if an end date is selected, if not, set the end date the same as beginning date.
    // If no beginning date is selected, it will follow existing validation rule.
    if (rangeDatePicker.value[0] && !rangeDatePicker.value[1]) {
      rangeDatePicker.inputFieldValue =  moment(rangeDatePicker.value[0]).format('MM/DD/YYYY') +
      ' - ' +  moment(rangeDatePicker.value[0]).format('MM/DD/YYYY'),
      rangeDatePicker.value[1] = rangeDatePicker.value[0];
    }
    rangeDatePicker.overlayVisible = false;
  }

  getTomorrow(): NgbDateStruct {
    const d = new Date();
    d.setDate(d.getDate() + 1);
    return this.dateToNgbStruct(d);
  }

  getToday(): NgbDateStruct {
    const d = new Date();
    return this.dateToNgbStruct(d);
  }

  getYesterday(): NgbDateStruct {
    const d = new Date();
    d.setDate(d.getDate() - 1);
    return this.dateToNgbStruct(d);
  }

  dateToNgbStruct(d: Date): NgbDateStruct {
    return { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() };
  }

  ngbStructToDate(ngd: NgbDateStruct): Date {
    return new Date(ngd.year, ngd.month - 1, ngd.day);
  }

  getModelStateErrors(obj: any): string[] {
    let errors = [];
    if (obj.errorMessages && obj.errorMessages.length > 0) {
      errors = obj.errorMessages.map(e => e.description);
    }
    for (const key of Object.keys(obj)) {
      const prop = obj[key] as InputField<any>;
      if (prop && !prop.isValid && prop.errorMessages && prop.errorMessages.length > 0) {
        errors = errors.concat(prop.errorMessages.map(e => e.description));
      }
    }
    return errors;
  }
}
