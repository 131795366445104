export interface PolicyListing {
    accam: string;
    agencyId: number;
    agentAccountId: number;
    agentCode: number;
    bookingID: number;
    bookingStatus: string;
    policyNumber: string;
    destination: string;
    createDate: string;
    departureDate: string;
    policyEffectiveDate: string;
    purchaseDate: string;
    returnDate: string;
    savedDate: string;
    maxMailStatus: string;
    // PNRLocatorNumber: string;
    // PNRSource: string;
    partnerTotalPrice: string;
    policyArchiveLocation: string;
    policyCode: string;
    primaryInsured: string;
    purchaseStatusCode: string;
    salesType: string;
    sortableDepartureDate: string;
    sortablePurchaseDate: string;
    sortableReturnDate: string;
    sortableSavedDate: string;
    status: string;
    totalPrice: number;
    totalTripCost: number;
}

export interface PolicyQueryResult {
    errorMessages: string[];
    isValid: boolean;
    // message: string;
    // transactionNumber: string;
    // transactionStatus: string;
    policies: PolicyListing[];
    // request: AMPolicyQuery;
}

export enum EmailOrPolicySelectionsEnum {
    EmailAddress = 1,
    PolicyNumber
  }
  
export enum DepartureOrPurchaseDateSelectionEnum {
    DepartureDate = 1,
    PurchaseDate
  }
  