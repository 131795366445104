import { ContentBlock } from '../../models/content-block.interface';
import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-content-block-container',
  templateUrl: './content-block-container.component.html',
  styleUrls: ['./content-block-container.component.scss']
})
export class ContentBlockContainerComponent implements OnInit {

  @Input() contentBlocks: Array<ContentBlock>;
  @Input() maxBlocks: number;
  public contentBlockClasses: any;
  public contentRows: Array<Array<ContentBlock>> = [];

  constructor() { }

  ngOnInit() {
    if (this.maxBlocks > 0) { this.contentBlocks = this.contentBlocks.splice(0, this.maxBlocks); }
    while (this.contentBlocks.length > 0) {
      const nextRowCount = this.contentBlocks.length >= 3 ? 3 : this.contentBlocks.length;
      this.contentRows.push(this.contentBlocks.splice(0, nextRowCount));
    }


  }

}
