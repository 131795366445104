import { Action } from '@ngrx/store';
import { createActionType } from '../../shared/utils';
import { Booking, EmailQuoteRequest, EmailInput, Report } from '../models';

export enum BookingActionTypes {
  SaveQuote = '[Booking] Save Quote',
  EmailQuote = '[Booking] Email Quote',
  EmailReceipt = '[Booking] Email Receipt',
  SaveQuoteSuccess = '[Booking] Save Quote Success',
  SaveQuoteFailure = '[Booking] Save Quote Failure'
}

export class SaveQuote implements Action {
  readonly type = BookingActionTypes.SaveQuote;

  constructor(
    public payload: {
      booking: Booking;
    }
  ) {}
}

export class SaveQuoteSuccess implements Action {
  readonly type = BookingActionTypes.SaveQuoteSuccess;

  constructor(public payload: Booking) {}
}

export class SaveQuoteFailure implements Action {
  readonly type = BookingActionTypes.SaveQuoteFailure;

  constructor(public payload: any) {}
}

export class EmailQuote implements Action {
  readonly type = BookingActionTypes.EmailQuote;

  constructor(
    public payload: {
      bookingId: number;
      emailQuoteRequest: EmailQuoteRequest;
    }
  ) {}
}

export class EmailReceipt implements Action {
  readonly type = BookingActionTypes.EmailReceipt;

  constructor(
    public payload: {
      bookingId: number;
      emailInput: EmailInput;
    }
  ) {}
}

export type BookingActionUnion = SaveQuote | EmailQuote | EmailReceipt | SaveQuoteFailure;
