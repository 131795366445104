
import {throwError as observableThrowError, interval as observableInterval,  Observable ,  Subscription } from 'rxjs';

import {share, take} from 'rxjs/operators';
import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-timeout-dialog',
  templateUrl: './timeout-dialog.component.html',
  styleUrls: ['./timeout-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TimeoutDialogComponent implements OnInit {

  public TIMEOUT: number = 60;
  private _timeoutSubscription: Subscription;
  public secondsRemaining$: Observable<number>;

  constructor(
    public dialogRef: MatDialogRef<TimeoutDialogComponent>,
  ) { }

  ngOnInit() {
    this.secondsRemaining$ = observableInterval(1000).pipe(
      take(this.TIMEOUT + 1),
      share(),);

    this._timeoutSubscription = this.secondsRemaining$
      .subscribe(
      next => next,
      error => observableThrowError(error),
      () => { this.dialogRef.close(false); }
      );
  }

  ngOnDestroy() {
    if (this._timeoutSubscription) {
      this._timeoutSubscription.unsubscribe();
    }
  }

}
