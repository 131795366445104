import { CanActivatePolicyGuard } from './shared/guards/can-activate-policy.guard';
import { CanActivateGuard } from './shared/guards/can-activate.guard';
import { ProductInformationComponent } from './components/product-information/product-information.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { FaqComponent } from './components/faq/faq.component';
import { HomeComponent } from './components/home/home.component';
import { NgModule } from '@angular/core';
import { ErrorComponent } from './components/error/error.component';
import { RouterModule, Routes, CanActivate, RouterStateSnapshot } from '@angular/router';
import { StaticSiteGuard } from './shared/guards/static-site.guard';
import { CoverageOptionsGuard } from './shared/guards/coverage-options.guard';
import { ActivatedRouteSnapshot } from '@angular/router/src/router_state';
import { PurchaseAgreementComponent } from './components/purchase-agreement/purchase-agreement.component';
import { CustomLandingPageComponent } from './components/custom-landing-page/custom-landing-page.component';
import { CanActivateClpGuard } from './shared/guards/can-activate-clp.guard';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { CanDeactivate } from '@angular/router/src/utils/preactivation';
import { CanDeactivatePrivacyGuard } from './shared/guards/can-deactivate-privacy.guard';
import { CanRedirectGuard } from './shared/guards/can-redirect.guard';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'allianz',
        pathMatch: 'full'
    },
    {
        path: 'legal',
        children:[{
            path: 'privacy',
            canActivate: [CanRedirectGuard],
            component: PrivacyComponent
        }]
    },
    {
        path: ':partner',
        children: [
            {
                path: '',
                redirectTo: 'home',
                pathMatch: 'full',
            },
            {
                path: 'AboutUs',
                redirectTo: 'about-us'
            },
            {
                path: 'FAQ',
                redirectTo: 'faq'
            },
            {
                path: 'ContactUs',
                redirectTo: 'about-us'
            },
            {
                path: 'CoverageOptions',
                redirectTo: 'coverage-options'
            },
            {
                path: 'Testimonials',
                redirectTo: 'coverage-options'
            },
            {
                path: 'purchaseAgreement',
                redirectTo: 'PurchaseAgreement'
            },
            {
                path: 'home',
                component: HomeComponent,
                canActivate: [CanActivateGuard]
            }, 
            {
                path: 'error',
                component: ErrorComponent,
                canActivate: [CanActivateGuard]
            },
            {
                path: 'faq',
                component: FaqComponent,
                canActivate: [CanActivateGuard]
            },
            {
                path: 'about-us',
                component: AboutUsComponent,
                canActivate: [CanActivateGuard]
            },
            {
                path: 'purchase-agreement',
                component: PurchaseAgreementComponent,
                canActivate: [CanActivateGuard]
            },
            {
                path: 'coverage-options',
                component: ProductInformationComponent,
                canActivate: [CoverageOptionsGuard]
            },
            {
                path: 'policy',
                loadChildren: './policy/policy.module#PolicyModule',
                canActivate: [CanActivatePolicyGuard]
            },
            {
                path: 'legal',
                children:[{
                    path: 'privacy',
                    component: PrivacyComponent,
                    canActivate: [CanActivateGuard],
                    canDeactivate: [CanDeactivatePrivacyGuard]
                }]
            },
            {
                path: 'clp/:uri',
                component: CustomLandingPageComponent,
                canActivate: [CanActivateClpGuard]
            },
            {
                path: '**',
                redirectTo: 'home'
            }
        ],
        canActivate: [StaticSiteGuard]
    },
    {
        path: '**',
        redirectTo: 'allianz'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [

    ]
})

export class AppRoutingModule { }
