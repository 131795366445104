import { QuoteActionTypes, QuoteActionUnion } from './../actions/quote';
import { QuoteComponentEnum } from '../models/quote-component.enum';
import { createSelector } from '@ngrx/store';
import { Booking, ProgramProductInformation, ProductBenefitInfo } from '../../policy/models';

export interface State {
  quote: Booking;
  programProducts: ProgramProductInformation[];
  globalBenefitList: ProductBenefitInfo[];
  error: string | null;
}

const initialState: State = {
  quote: null,
  programProducts: [],
  globalBenefitList: [],
  error: null
};


export function reducer(state = initialState, action: QuoteActionUnion): State {
  switch (action.type) {
    case QuoteActionTypes.GetCarRentalQuoteSuccess:
    case QuoteActionTypes.GetTravelQuoteSuccess:
    case QuoteActionTypes.GetTravelAgeRatedQuoteSuccess: {
      return {
        ...state,
        quote: action.payload.quote,
        programProducts: action.payload.programProducts,
        globalBenefitList: action.payload.globalBenefitList,
        error: null
      };
    }

    case QuoteActionTypes.GetCarRentalQuoteFailure:
    case QuoteActionTypes.GetTravelQuoteFailure:
    case QuoteActionTypes.GetTravelAgeRatedQuoteFailure: {
      return {
        ...state,
        error: action.payload
      };
    }

    default: {
      return state;
    }
  }
}

