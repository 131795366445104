import { Injectable } from '@angular/core';
import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { LoadingIndicatorComponent } from '../components/loading-indicator/loading-indicator.component';
import { ComponentPortal } from '@angular/cdk/portal';

@Injectable()
export class LoadingService {

  private _overlayRef;

  constructor(private overlay: Overlay) { }

  open() {

    const positionStrategy = this.overlay.position()
      .global()
      .centerHorizontally()
      .centerVertically();

    const overlayConfig = new OverlayConfig();
    overlayConfig.hasBackdrop = true;
    overlayConfig.scrollStrategy = this.overlay.scrollStrategies.block();
    overlayConfig.positionStrategy = positionStrategy;

    const portal = new ComponentPortal(LoadingIndicatorComponent);

    this._overlayRef = this.overlay.create(overlayConfig);

    this._overlayRef.attach(portal);
  }

  close() {
    if (typeof this._overlayRef !== 'undefined') {
      this._overlayRef.dispose();
    }
  }

}
