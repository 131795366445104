
import {timer as observableTimer,  Subject ,  Observable ,  Subscription } from 'rxjs';

import {tap, mergeMap} from 'rxjs/operators';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Injectable } from '@angular/core';

import { TimeoutDialogComponent } from '../components/dialogs/timeout-dialog/timeout-dialog.component';

@Injectable()
export class TimeoutService {

  // 
  //  Current setting: 20 minute timeout.
  //  At one minute remaining, a one-minute-long dialog wll appear.
  //  See shared/components/dialogs/timeout-dialog.ts
  //
  private TIMEOUT_MS = 1000 * 60 * 20;
  private timeoutSubscription: Subscription;

  private timeout = new Subject<boolean>();

  public timeoutObservable$ = this.timeout.asObservable();

  constructor(
    public dialog: MatDialog
  ) { }

  startSessionTimer() {

    if (this.timeoutSubscription) {
      this.timeoutSubscription.unsubscribe();
    }

    this.timeoutSubscription = observableTimer(this.TIMEOUT_MS - (1000 * 60 * 1)).pipe(
      mergeMap(t => this.openDialog()),
      tap(result => this.timeout.next(result)),
      tap(result => {
        if (result === true) {
          this.refreshSessionTimer();
        }
      }),)
      .subscribe();
  }

  private refreshSessionTimer() {
    this.startSessionTimer();
  }

  private openDialog(): Observable<boolean> {
    let dialogRef = this.dialog.open(TimeoutDialogComponent, {
      disableClose: true,
      width: '320px'
    });

    return dialogRef.afterClosed();
  }

}
