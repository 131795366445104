import { ContentManagementService } from './../services/content-management.service';
import { Router, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { CanActivateGuard } from './can-activate.guard';
import { Injectable } from '@angular/core';
import { CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AppStateService } from '../services/app-state.service';
import { TimeoutService } from '../services/timeout.service';
import { Location } from '@angular/common';

@Injectable()
export class CoverageOptionsGuard extends CanActivateGuard implements CanActivate {

  constructor(
    route: ActivatedRoute,
    router: Router,
    location: Location,
    contentManagementService: ContentManagementService,
    appStateService: AppStateService,
    timeoutService: TimeoutService
  ) { super(route, router, location, contentManagementService, appStateService, timeoutService) }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return (super.canActivate(route, state) as Promise<boolean>)
      .then(() => {
        const config = this.appStateService.getPartnerConfig();

        if (config.displayProductPage) {
          return true;
        } else {
          this.router.navigateByUrl(this._partnerParameterName);
        }
      }).catch(() => {
        this.router.navigateByUrl(this.DEFAULT_PARTNER);
        return false;
      });

  }
}
