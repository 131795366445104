import { BookingActionTypes, BookingActionUnion } from './../actions/booking';
import { createSelector } from '@ngrx/store';
import { ErrorMessage } from '../../shared/models';
import {
  AdditionalInformation,
  BookingAgentInformation,
  FlightItineraryInformation,
  GDSInformation,
  HotelInformation,
  OrderInformation,
  TripInformation,
  TravelInformation,
  RentalCarInformation,
  QuoteProductInformation,
  SecondChanceInformation,
  MarketingInformation,
  Booking,
  EmailQuoteRequest,
  EmailInput
} from '../models';

export interface State {
  booking: Booking;
  emailQuoteRequest: EmailQuoteRequest;
  emailInput: EmailInput;
  bookingId: number;
}

const initialState: State = {
  booking: null,
  emailQuoteRequest: null,
  emailInput: null,
  bookingId: null
};

export function reducer(state = initialState, action: BookingActionUnion): State {
  switch (action.type) {
    case BookingActionTypes.SaveQuote: {
      return {
        ...state,
        booking: action.payload.booking
      };
    }

    case BookingActionTypes.SaveQuoteFailure: {
      return {
        ...state,
        booking: action.payload.error.modelState
      };
    }

    case BookingActionTypes.EmailQuote: {
      return {
        ...state,
        bookingId: action.payload.bookingId,
        emailQuoteRequest: action.payload.emailQuoteRequest
      };
    }

    case BookingActionTypes.EmailReceipt: {
      return {
        ...state,
        bookingId: action.payload.bookingId,
        emailInput: action.payload.emailInput
      };
    }

    default: {
      return state;
    }
  }
}
