import { AppStateService } from './../../shared/services/app-state.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent {

  public pageTitle: string = `About Us`;
  aboutUsContent: string;

  constructor(
    private route: ActivatedRoute,
    private appStateService: AppStateService
  ) { }

  ngOnInit() {
    const globalContent = this.appStateService.getGlobalContent();
    if (globalContent) {
      this.aboutUsContent = globalContent.aboutUs;
    }

    this.appStateService.globalContentObservable$.subscribe(gc => {
      this.aboutUsContent = gc.aboutUs;
    });
  }

}
