  <div *ngIf="!clp" class="nav-container">
    <nav class="navbar navbar-light navbar-expand-md">

      <div class="navbar-items d-md-none">
        <div class="col">
          <a class="navbar-brand" href="#" [attr.aria-hidden]="!logoHref">
            <a *ngIf="logoHref" [href]="logoHref" target="_blank"><img class="logo" alt="Logo" [src]="logoUrl"></a>
            <img class="logo" alt="Logo" *ngIf="!logoHref && !!logoUrl" [src]="logoUrl">
          </a>
        </div>
        <div class="col get-help">
          <app-telephone [phoneNumber]="phoneNumber" class="" [displayText]="'Get Help'"></app-telephone>
        </div>
        <div class="col menu">
          <button (click)="toggleMenu()" class="navbar-toggler" type="button" aria-controls="navbarLinksJs"
            aria-label="Toggle navigation">
            <span *ngIf="expanded === false" class="fa fa-bars fa-lg"></span>
            <span *ngIf="expanded === true" class="fa fa-times-circle-o fa-lg"></span>
          </button>
        </div>
      </div>


      <div id="navbarLinksJs" class="collapse navbar-collapse justify-content-start" [style.background-color]="navbarBackgroundColor">
        <ul class="navbar-nav">
          <li class="nav-item" *ngFor="let link of links">
            <a *ngIf="!link.isExternal && link.active" class="nav-link d-none d-md-block" (mouseover)="link.hovering = true" (mouseout)="link.hovering = false"
              [style.background-color]="(link.hovering || isActive([partnerName, link.route])) ? navbarHoverColor : navbarBackgroundColor"
              [routerLink]="[partnerName, link.route]" [class.is-active]="isActive([partnerName, link.route])" title="{{link.name}}">{{link.name}}</a>
            <a *ngIf="link.isExternal && link.active" class="nav-link d-none d-md-block" (mouseover)="link.hovering = true" (mouseout)="link.hovering = false"
              [style.background-color]="link.hovering ? navbarHoverColor : navbarBackgroundColor" href="{{link.route}}" target="_blank" rel="noopener" title="{{link.name}}">{{link.name}}&nbsp;&nbsp;
              <span class="fa fa-external-link" aria-hidden="true"></span>
            </a>
            <a (click)="navlinkClicked()" *ngIf="!link.isExternal && link.active" class="nav-link d-md-none" (mouseover)="link.hovering = true" (mouseout)="link.hovering = false"
              [style.background-color]="(link.hovering || isActive([partnerName, link.route])) ? navbarHoverColor : navbarBackgroundColor"
              [routerLink]="[partnerName, link.route]" [class.is-active]="isActive([partnerName, link.route])" title="{{link.name}}">{{link.name}}</a>
            <a (click)="navlinkClicked()" *ngIf="link.isExternal && link.active" class="nav-link d-md-none" (mouseover)="link.hovering = true" (mouseout)="link.hovering = false"
              [style.background-color]="link.hovering ? navbarHoverColor : navbarBackgroundColor" href="{{link.route}}" target="_blank" title="{{link.name}}">{{link.name}}&nbsp;&nbsp;
              <span class="fa fa-external-link" aria-hidden="true"></span>
            </a>
          </li>
        </ul>
      </div>

    </nav>
</div>