
import {throwError as observableThrowError, forkJoin as observableForkJoin, of as observableOf,  Observable ,  Subject } from 'rxjs';

import {catchError, map, tap} from 'rxjs/operators';
import { AlertService } from './../alert/services/alert.service';
import { LoadingService } from './loading.service';
import { ContentBlock, Faq, Site, GlobalContent } from './../models';
import { AppStateService } from './app-state.service';




import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable, ComponentFactoryResolver } from '@angular/core';
import { environment } from './../../../environments/environment';
import * as _ from 'lodash';
import { NetworkService } from './network.service';
import { Injector } from '@angular/core';

@Injectable()
export class ContentManagementService extends NetworkService {

  // private _partnerName = new Subject<string>();

  // public partnerNameObservable$ = this._partnerName.asObservable();

  private _agencyConfigSource = new Subject<Site>();

  public agencyConfig$ = this._agencyConfigSource.asObservable();

  private _headers = new HttpHeaders().set('shouldAuth', 'false');

  constructor(
    private http: HttpClient,
    private appStateService: AppStateService,
    injector: Injector
  ) { super(injector); }

  getFaqs(ids: number[], partnerName: string): Observable<Array<Faq>> {
    const faqCalls: Array<Observable<Faq>> = ids.map(id =>
      this.http.get<Faq>(`${environment.apiGatewayUrl}/content/faq/${id}?partner=${partnerName}`, { headers: this._headers })
    );

    return observableForkJoin(faqCalls).pipe(catchError(this.handleError));
  }

  getContentBlocks(ids: number[]): Observable<Array<ContentBlock>> {
    const contentBlockCalls: Array<Observable<ContentBlock>> = ids.map(id =>
      this.http.get<ContentBlock>(`${environment.apiGatewayUrl}/content/home-content-block/${id}`, { headers: this._headers })
    );

    return observableForkJoin(contentBlockCalls).pipe(catchError(this.handleError));
  }

  getProductInformation(ids: number[]): Observable<Array<ContentBlock>> {
    const contentBlockCalls: Array<Observable<ContentBlock>> = ids.map(id =>
      this.http.get<ContentBlock>(`${environment.apiGatewayUrl}/content/product-content-block/${id}`, {headers: this._headers })
    );

    return observableForkJoin(contentBlockCalls).pipe(catchError(this.handleError));
  }

  
  tryGetGlobalContent(): Observable<GlobalContent> {
    const globalContent = this.appStateService.getGlobalContent();
    
    if (typeof (globalContent) !== 'undefined' && globalContent !== null) {
      return observableOf(globalContent);
    } else {
      return this.getGlobalContent().pipe(
      tap(gc => {
        this.appStateService.setGlobalContent(gc);
      }));
    }
  }
  
  public getAgencyConfig(partnerName: string): Observable<Site> {
    return this.http.get<Site>(`${environment.apiGatewayUrl}/site/${partnerName}`, { headers: this._headers }).pipe(catchError(this.handleError));
  }
  
  public tryGetAgencyConfig(): Observable<Site> {
    
    const sessionPartnerConfig = this.appStateService.getPartnerConfig();
    
    if (typeof (sessionPartnerConfig) !== 'undefined' && sessionPartnerConfig !== null) {
      return observableOf(sessionPartnerConfig);
    }
    
    return observableThrowError(`Partner config not found.`);
  }
  
  public checkPartnerParam(partnerParam: string): Observable<boolean> {
    const obs = new Observable<boolean>(observer => {
      partnerParam = _.toLower(partnerParam);
      const existingPartnerName = _.toLower(this.appStateService.getPartnerName());
      
      if (existingPartnerName !== partnerParam) {
        observer.next(true);
      } else {
        observer.next(false);
      }
      
      observer.complete();
    });
    
    return obs;
  }

  private getGlobalContent(): Observable<GlobalContent> {
    return this.http.get<GlobalContent>(`${environment.apiGatewayUrl}/global-config`, { headers: this._headers }).pipe(catchError(this.handleError));
  }

  public getClpContent(uri: string, partner: string): Observable<any> {
    return this.http.get<any>(`${environment.apiGatewayUrl}/content/custom-landing-page/${uri}?partner=${partner}`, { headers: this._headers }).pipe(catchError(this.handleError));
  }
}
