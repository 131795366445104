import { Action } from '@ngrx/store';
import { createActionType } from '../../shared/utils';
import { AlertTypeEnum } from '../alert/alert-type.enum';


export enum AlertActionTypes {
  Alert = '[Alert]',
}

export class Alert implements Action {
  readonly type = AlertActionTypes.Alert;

  constructor(
    public payload: {
      message: string;
      alertType: AlertTypeEnum
    }
  ) {}
}


export type AlertActionUnion = Alert;
