import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AlertTypeEnum } from '../../../alert/alert-type.enum';

@Component({
  selector: 'app-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InfoDialogComponent implements OnInit {

  alertTypeEnum = AlertTypeEnum;

  constructor(
    public dialogRef: MatDialogRef<InfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string, message: string, type: AlertTypeEnum }) { }

  ngOnInit() {
  }

}
