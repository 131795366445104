import { Product } from "./index";

export class Transaction {
    transactionId: string;
    transactionAffiliation?: string;
    transactionTotal: number;
    transactionShipping?: number;
    transactionTax?: number;
    transactionProducts?: Array<Product>;

    constructor() {
        this.transactionProducts = [];
    }
}
