<form [formGroup]="managePolicyForm" (ngSubmit)="onSubmit()" class="form" novalidate>
  <div class="form-row">
    <div [ngClass]="isSessionSeason ? 'col-md-6' : 'col-md-5'">
      <div class="form-group">
        <label class="text-nowrap" for="emailOrPolicySelection">Email Address or Policy Number</label>
        <div class="row">
          <div class="col">
            <select id="emailOrPolicySelection" (change)="emailOrPolicy.reset()" class="form-control form-control-sm" formControlName="emailOrPolicySelection">
              <option *ngFor="let opt of emailOrPolicySelections" [value]="opt.value">{{opt.name}}</option>
            </select>
            <app-input-validation [inputFormControl]="emailOrPolicySelection"></app-input-validation>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="text-nowrap" for="emailOrPolicy">{{getEmailOrPolicyNumberLabel()}}</label>
        <div class="row">
          <div class="col">
            <input id="emailOrPolicy" class="form-control form-control-sm" formControlName="emailOrPolicy" />
            <app-input-validation [inputFormControl]="emailOrPolicy"></app-input-validation>
          </div>
        </div>
      </div>
    </div>
    <div [ngClass]="isSessionSeason ? 'col-md-6' : 'col-md-5'">
      <div class="form-group">
        <label  class="text-nowrap"  for="departureOrPurchaseDateSelection">{{dateTypeSelectionLabel}}</label>
        <div class="row">
          <div class="col">
            <select id="departureOrPurchaseDateSelection"  class="form-control form-control-sm"
              formControlName="departureOrPurchaseDateSelection">
              <option *ngFor="let opt of dateTypeSelections" [value]="opt.value">{{opt.name}}</option>
            </select>
            <app-input-validation [inputFormControl]="departureOrPurchaseDateSelection"></app-input-validation>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="departureOrPurchaseDate">{{getDateSelectionLabel()}}
        </label>
        <div class="row">
          <div class="col">
            <app-date-picker [labelForId]="'departureOrPurchaseDate'" formControlName="departureOrPurchaseDate"></app-date-picker>
            <app-input-validation [inputFormControl]="departureOrPurchaseDate"></app-input-validation>
          </div>
        </div>
      </div>
    </div>
    <div [ngClass]="isSessionSeason ? 'col-md-2' : 'col-md-2'">
        <div class="form-group">
          <label  class="text-nowrap"  for="zipCode">Zip Code</label>
          <div class="row">
            <div class="col">
              <input id="zipCode" class="form-control form-control-sm" formControlName="zipCode" maxlength="13" title="Please use your residence or billing zip code" />
              <app-input-validation [inputFormControl]="zipCode"></app-input-validation>
            </div>
          </div>
        </div>
    </div>
  </div>
  <app-form-validation [errorMessages]="errors"></app-form-validation>
  <div class="form-row">
    <div class="col">
      <app-dynamic-button [compId]="'manage-btn'" type="submit" class="pull-right" [disabled]="buttonDisabled" [buttonText]="'Submit'" [buttonHoverColor]="(colorObs | async).colors.secondaryButtonColor"
        [buttonBackgroundColor]="(colorObs | async).colors.primaryButtonColor" *ngIf="showSubmitButton"></app-dynamic-button>
    </div>
  </div>

</form>