import { PolicyConfirmation, State, SelectListItem } from './../models';
import { Policy, PolicyListing, Booking, ProductBenefitInfo } from './../../policy/models';
import { Quote } from '../../quote/models/quote.interface';
import { Site, ToggleNavLink, GlobalContent } from './../models';
import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { NavigationEnum } from '../../components/navbar/navigation.enum';
import { QuoteWidgetFormData } from '../../quote/models/form-data.interface';

@Injectable()
export class AppStateService {
  private PARTNER_NAME_KEY: string = `PARTNER_NAME`;
  private CONFIG_KEY: string = `PARTNER_CONFIG`;
  private GLOBAL_CONFIG_KEY = `GLOBAL_CONFIG`;
  private TOKEN_KEY: string = `PARTNER_TOKEN_KEY`;
  private POLICY_SEARCH_FORM_KEY: string = `POLICY_SEARCH_FORM`;
  private POLICY_CURRENT_OBJECT_KEY = 'POLICY_CURRENT_OBJECT';
  private POLICY_CURRENT_LISTING_KEY = 'POLICY_CURRENT_LISTING';
  private PURCHASE_QUOTE_KEY = 'PURCHASE_QUOTE';
  private PURCHASE_QUOTE_FORM_KEY = 'PURCHASE_QUOTE_FORM';
  private PURCHASE_CURRENT_BOOKING_KEY = 'PURCHASE_CURRENT_BOOKING';
  private POLICY_CONFIRMATION_KEY = 'POLICY_CONFIRMATION';
  private SESSION_STATE_KEY = 'SESSION_STATE';
  private STATES_KEY = 'STATES';
  private DESTINATIONS_KEY = 'DESTINATIONS';
  private CSID_KEY = 'CSID';
  private GLOBAL_BENEFITS_LIST_KEY = 'GLOBAL_BENEFITS_LIST';

  // Notifies navbar and footer with updated partner for links.
  private partnerName = new Subject<string>();

  public partnerNameObservable$ = this.partnerName.asObservable();

  private agencyInfo = new Subject<Site>();

  public agencyInfoObservable$ = this.agencyInfo.asObservable();

  private activeLinks = new Subject<ToggleNavLink>();

  public activeLinksObservable$ = this.activeLinks.asObservable();

  private quoteListing = new Subject<Quote>();

  public quoteListingObservable$ = this.quoteListing.asObservable();

  private globalContent = new Subject<GlobalContent>();

  public globalContentObservable$ = this.globalContent.asObservable();

  private globalBenefits = new Subject<Quote>();

  public globalBenefitsObservable$ = this.globalBenefits.asObservable();

  constructor() { }

  clearAppState() {
    sessionStorage.clear();
  }

  public broadcastPartnerName() {
    this.partnerName.next(this.getPartnerName());
  }

  public broadcastAgencyInfo() {
    this.agencyInfo.next(this.getPartnerConfig());
  }

  public broadcastGlobalContent() {
    this.globalContent.next(this.getGlobalContent());
  }

  public broadcastQuote(q: Quote) {
    this.quoteListing.next(q);
  }

  public broadcastGlobalBenefits(q: Quote) {
    this.globalBenefits.next(q);
  }

  public tryBroadcastActiveMyPoliciesLink() {
    const sessionActive = this.getManageSessionActive();

    if (sessionActive === true) {
      this.toggleActiveLink(NavigationEnum.MyPolicies, true);
    } else {
      this.toggleActiveLink(NavigationEnum.MyPolicies, false);
    }
  }

  public toggleActiveLink(navLinkType: NavigationEnum, active: boolean) {
    this.activeLinks.next({ navLinkType: navLinkType, active: active });
  }

  public concludePurchaseSession() {
    this.clearPurchaseState();
    sessionStorage.removeItem(this.CSID_KEY);
  }

  public clearPurchaseState() {
    sessionStorage.removeItem(this.PURCHASE_QUOTE_FORM_KEY);
    sessionStorage.removeItem(this.PURCHASE_QUOTE_KEY);
    sessionStorage.removeItem(this.PURCHASE_CURRENT_BOOKING_KEY);
    this.setPurchaseSessionActive(false);
  }

  public clearManagePolicyState() {
    this.toggleActiveLink(NavigationEnum.MyPolicies, false);
    sessionStorage.removeItem(this.POLICY_SEARCH_FORM_KEY);
    sessionStorage.removeItem(this.POLICY_CURRENT_OBJECT_KEY);
    sessionStorage.removeItem(this.POLICY_CURRENT_LISTING_KEY);
    this.setManageSessionActive(false);
  }

  public clearUserSessionToken() {
    sessionStorage.removeItem(this.TOKEN_KEY);
  }

  public clearGlobalBenefitsList() {
    sessionStorage.removeItem(this.GLOBAL_BENEFITS_LIST_KEY);
  }

  public getPartnerName(): string { return sessionStorage.getItem(this.PARTNER_NAME_KEY); }
  public setPartnerName(value: string): void { sessionStorage.setItem(this.PARTNER_NAME_KEY, value); }

  public getPartnerConfig(): Site { return JSON.parse(sessionStorage.getItem(this.CONFIG_KEY)); }
  public setPartnerConfig(value: Site): void { sessionStorage.setItem(this.CONFIG_KEY, JSON.stringify(value)); }

  public getGlobalContent(): GlobalContent { return JSON.parse(sessionStorage.getItem(this.GLOBAL_CONFIG_KEY)); }
  public setGlobalContent(value: GlobalContent): void { sessionStorage.setItem(this.GLOBAL_CONFIG_KEY, JSON.stringify(value)); }

  public getPartnerToken(): string { return sessionStorage.getItem(this.TOKEN_KEY); }
  public setPartnerToken(value: string): void { sessionStorage.setItem(this.TOKEN_KEY, value); }

  public getManageSessionActive(): boolean {
    return sessionStorage.getItem(this.SESSION_STATE_KEY) === SessionStateEnum.Manage;
  }
  public setManageSessionActive(active: boolean) {
    sessionStorage.setItem(this.SESSION_STATE_KEY, active ? SessionStateEnum.Manage : SessionStateEnum.None);
  }

  public getPolicySearchForm() { return JSON.parse(sessionStorage.getItem(this.POLICY_SEARCH_FORM_KEY)); }
  public setPolicySearchForm(form: any) { sessionStorage.setItem(this.POLICY_SEARCH_FORM_KEY, JSON.stringify(form)); }

  public getCurrentPolicy(): Policy { return JSON.parse(sessionStorage.getItem(this.POLICY_CURRENT_OBJECT_KEY)); }
  public setCurrentPolicy(p: Policy) {
    sessionStorage.setItem(this.POLICY_CURRENT_OBJECT_KEY, JSON.stringify(p));
    this.setCurrentPolicyListing(p.policyListing);
  }
  public clearCurrentPolicy() { sessionStorage.removeItem(this.POLICY_CURRENT_OBJECT_KEY); }

  public getCurrentPolicyListing(): PolicyListing { return JSON.parse(sessionStorage.getItem(this.POLICY_CURRENT_LISTING_KEY)); }
  public setCurrentPolicyListing(pl: PolicyListing) { sessionStorage.setItem(this.POLICY_CURRENT_LISTING_KEY, JSON.stringify(pl)); }

  public getQuoteForm(): QuoteWidgetFormData { return JSON.parse(sessionStorage.getItem(this.PURCHASE_QUOTE_FORM_KEY)); }
  public setQuoteForm(form: QuoteWidgetFormData) { sessionStorage.setItem(this.PURCHASE_QUOTE_FORM_KEY, JSON.stringify(form)); }
  public clearQuoteForm() { sessionStorage.removeItem(this.PURCHASE_QUOTE_FORM_KEY); }

  public getQuote(): Quote { return JSON.parse(sessionStorage.getItem(this.PURCHASE_QUOTE_KEY)) }
  public setQuote(q: Quote) { sessionStorage.setItem(this.PURCHASE_QUOTE_KEY, JSON.stringify(q)) }

  public getPurchaseSessionActive(): boolean { return sessionStorage.getItem(this.SESSION_STATE_KEY) === SessionStateEnum.Purchase; }
  public setPurchaseSessionActive(active: boolean) { sessionStorage.setItem(this.SESSION_STATE_KEY, active ? SessionStateEnum.Purchase : SessionStateEnum.None); }

  public getConfirmedPolicy(): PolicyConfirmation { return JSON.parse(sessionStorage.getItem(this.POLICY_CONFIRMATION_KEY)); }
  public setConfirmedPolicy(p: PolicyConfirmation) { sessionStorage.setItem(this.POLICY_CONFIRMATION_KEY, JSON.stringify(p)); }

  public getCurrentBooking(): Booking { return JSON.parse(sessionStorage.getItem(this.PURCHASE_CURRENT_BOOKING_KEY)); }
  public setCurrentBooking(b: Booking) { sessionStorage.setItem(this.PURCHASE_CURRENT_BOOKING_KEY, JSON.stringify(b)); }

  public getStates(): Array<State> { return JSON.parse(sessionStorage.getItem(this.STATES_KEY)) }
  public setStates(states: Array<State>) { sessionStorage.setItem(this.STATES_KEY, JSON.stringify(states)); }

  public getDestinations(): Array<SelectListItem> { return JSON.parse(sessionStorage.getItem(this.DESTINATIONS_KEY)) }
  public setDestinations(dests: Array<SelectListItem>) { sessionStorage.setItem(this.DESTINATIONS_KEY, JSON.stringify(dests)); }

  public getCSID(): string { return sessionStorage.getItem(this.CSID_KEY); }
  public setCSID(csid: string) { sessionStorage.setItem(this.CSID_KEY, csid); }

  public getGlobalBenefitsList(): Quote { return JSON.parse(sessionStorage.getItem(this.GLOBAL_BENEFITS_LIST_KEY)); }
  public setGlobalBenefitsList(q: Quote) { sessionStorage.setItem(this.GLOBAL_BENEFITS_LIST_KEY,
    JSON.stringify(q)); }
}

export enum SessionStateEnum {
  None = 'none',
  Manage = 'manage',
  Purchase = 'purchase'
}
