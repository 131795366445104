<mat-progress-bar mode="indeterminate" [class.active]="!!showProgressBar" [class.inactive]="!showProgressBar"></mat-progress-bar>
<app-warning-banner [displayBanner]="config?.displayWarningBanner || false" [domain]="domain"></app-warning-banner>
<div class="container">
  <app-header></app-header>
  <app-navbar></app-navbar>
  <main>
    <router-outlet></router-outlet>
    <app-alert></app-alert>
  </main>
</div>
<app-footer></app-footer>
