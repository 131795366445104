import { AlertActionTypes, AlertActionUnion } from './../actions/alert';
import { createSelector } from '@ngrx/store';
import { AlertTypeEnum } from '../alert/alert-type.enum';



export interface State {
  message: string;
  alertType: AlertTypeEnum;
}

const initialState: State = {
  message: '',
  alertType: null
};

export function reducer(state = initialState, action: AlertActionUnion): State {
  switch (action.type) {
    case AlertActionTypes.Alert: {
      return {
        ...state,
        message: action.payload.message,
        alertType: action.payload.alertType
      };
    }

    default: {
      return state;
    }
  }
}

export const getAlertType = (state: State) => state.alertType;
export const getMessage = (state: State) => state.message;
