import { StoreModule } from '@ngrx/store';
import {
  routerReducer,
  StoreRouterConnectingModule,
  RouterStateSerializer
} from '@ngrx/router-store';
import { environment } from '../environments/environment';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ContactUsComponent } from './shared/components/contact-us/contact-us.component';
import { TelephoneComponent } from './shared/components/telephone/telephone.component';
import { CoverageOptionsGuard } from './shared/guards/coverage-options.guard';
import { ReactiveFormsModule } from '@angular/forms';
import { LoadingIndicatorComponent } from './shared/components/loading-indicator/loading-indicator.component';
import { GlobalErrorHandler } from './shared/services/global-error-handler';
import { CanActivatePolicyGuard } from './shared/guards/can-activate-policy.guard';
import { CanActivateGuard } from './shared/guards/can-activate.guard';
import { QuoteModule } from './quote/quote.module';
import { FaqComponent } from './components/faq/faq.component';
import { HomeComponent } from './components/home/home.component';
import { ErrorComponent } from './components/error/error.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  ErrorHandler,
  SkipSelf,
  Optional
} from '@angular/core';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { BrowserModule, Title } from '@angular/platform-browser';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { ProductInformationComponent } from './components/product-information/product-information.component';
import { ContentBlockContainerComponent } from './shared/components/content-block-container/content-block-container.component';
import { ContentBlockComponent } from './shared/components/content-block-container/content-block/content-block.component';
import { PhonePipe } from './shared/pipes/phone.pipe';
import { AuthInterceptor } from './shared/interceptors/auth.interceptor';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AlertModule } from './shared/alert/alert.module';
import { CurrencyPipe } from '@angular/common';
import { LoadingService } from './shared/services/loading.service';
import { StaticSiteGuard } from './shared/guards/static-site.guard';
import { CanDeactivateGuard } from './shared/guards/can-deactivate.guard';
import { BROWSER_FAVICONS_CONFIG } from './favicons';
import { BrowserFavicons } from './favicons';
import { Favicons } from './favicons';
import { EffectsModule } from '@ngrx/effects';
import { AppEffects } from './effects/app.effects';
import { appMetaReducers, appReducer } from './reducers/app.reducer';
import { CustomSerializer } from './shared/utils';
import * as fromQuote from './quote/reducers';
import { GenerateQuoteEffects } from './quote/effects/generate-quote';
import { BookingEffects } from './policy/effects/booking';
import * as fromPolicy from './policy/reducers';
import * as fromShared from './shared/reducers';
import { WarningBannerComponent } from './components/warning-banner/warning-banner.component';
import { PurchaseAgreementComponent } from './components/purchase-agreement/purchase-agreement.component';
import { CustomLandingPageComponent } from './components/custom-landing-page/custom-landing-page.component';
import { CanActivateClpGuard } from './shared/guards/can-activate-clp.guard';
import { WidgetWrapperComponent } from './components/custom-landing-page/widget-wrapper/widget-wrapper.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { CanDeactivatePrivacyGuard } from './shared/guards/can-deactivate-privacy.guard';
import { CanRedirectGuard } from './shared/guards/can-redirect.guard';
import { CryptoService } from './shared/services/crypto.service';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    NavbarComponent,
    HomeComponent,
    FaqComponent,
    ErrorComponent,
    AboutUsComponent,
    ProductInformationComponent,
    ContentBlockComponent,
    ContentBlockContainerComponent,
    LoadingIndicatorComponent,
    TelephoneComponent,
    ContactUsComponent,
    WarningBannerComponent,
    PurchaseAgreementComponent,
    CustomLandingPageComponent,
    WidgetWrapperComponent,
    PrivacyComponent
  ],
  entryComponents: [LoadingIndicatorComponent],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    QuoteModule.forRoot(),
    SharedModule.forRoot(),
    AppRoutingModule,
    NgbModule,
    ScrollToModule.forRoot(),
    AlertModule.forRoot(),
    StoreModule.forRoot(appReducer, {
      metaReducers: appMetaReducers,
    }),
    StoreModule.forRoot({
      router: routerReducer
    }),
    StoreModule.forFeature('quote', fromQuote.reducers),
    StoreModule.forFeature('policy', fromPolicy.reducers),
    StoreModule.forFeature('shared', fromShared.reducers),
    StoreRouterConnectingModule.forRoot({
      stateKey: 'router'
    }),
    EffectsModule.forRoot([AppEffects]),
    EffectsModule.forFeature([GenerateQuoteEffects]),
    !environment.production ? StoreDevtoolsModule.instrument() : []
  ],
  providers: [
    CryptoService,
    CanActivateGuard,
    CoverageOptionsGuard,
    CanActivatePolicyGuard,
    CanRedirectGuard,
    StaticSiteGuard,
    CurrencyPipe,
    CanDeactivateGuard,
    CanDeactivatePrivacyGuard,
    CanActivateClpGuard,
    Title,
    { provide: Favicons, useClass: BrowserFavicons },
    { provide: BROWSER_FAVICONS_CONFIG,
      useValue: {
        icons: {
          'none': {
            type: 'image/x-icon',
            href: '#'
          },
          'allianz': {
            type: 'image/x-icon',
            href: '/icons/allianz.ico'
          },
          'partner': {
            type: 'image/png',
            href: '/icons/eTravel_Favicon.png'
          }
        },
        // Determine whether or not a random token is auto-appended to the HREF values
        // whenever an icon is injected into the document
        cacheBusting: true
      },

    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: RouterStateSerializer, useClass: CustomSerializer }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
