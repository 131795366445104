import { UtilService } from './../../../shared/services/util.service';
import { ESitesNgbDateParserFormatter } from './../../services/date-parser-formatter';
import { QuoteService } from './../../services/quote.service';
import { State } from './../../../shared/models/state.interface';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators
} from '@angular/forms';
import {
  NgbDateStruct,
  NgbDateParserFormatter
} from '@ng-bootstrap/ng-bootstrap';
import { ToolTipConstants } from '../../../shared/constants/tool-tip-constants';
import { AppStateService } from '../../../shared/services/app-state.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import * as AlertMessages from '../../../shared/constants/alert-messages.constants';
import { Store } from '@ngrx/store';
import { QuoteState } from '../../reducers';
import { Actions, ofType } from '@ngrx/effects';
import { GetEventTicketQuote } from '../../actions/generate-quote';
import { map } from 'rxjs/operators';
import { QuoteActionTypes } from '../../actions/quote';
import * as moment from 'moment';
import { ButtonEnum } from '../../models/button.enum';
import { GoogleAnalyticsEventsService } from '../../../shared/services/google-analytics-events.service';
import { EventTicketFormData } from '../../models/form-data.interface';

@Component({
  selector: 'app-event-registration',
  templateUrl: './event-registration.component.html',
  styleUrls: ['./event-registration.component.scss'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: ESitesNgbDateParserFormatter },
    ESitesNgbDateParserFormatter
  ]
})

export class EventRegistrationComponent implements OnInit {
  registrationCostTooltipMessage: string = ToolTipConstants.nonTravelTotalCost;
  public eventRegistrationForm: FormGroup;
  public states: Array<State>;
  errors: String[] = [];
  buttonDisabled = false;
  partnerName: string;
  displayMonths: number;
  minDateTomorrow: Date;
  /*
  * Configure the text of the submit button for the Get a Quote tab.
  */
  @Input() quoteButtonText: string;

  /*
  * Quote tab submit button background color.
  */
  @Input() quoteButtonColor: string;

  /*
  * Quote tab submit button background hover color.
  */
  @Input() quoteButtonHoverColor: string;

  @Input() componentId: string;

  @ViewChild('rangeDatePicker') rangeDatePicker;

  constructor(
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private _dateFormatter: ESitesNgbDateParserFormatter,
    private quoteService: QuoteService,
    private appStateService: AppStateService,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<QuoteState>,
    private action$: Actions,
    private ga: GoogleAnalyticsEventsService
  ) {
    this.eventRegistrationForm = this.formBuilder.group({
      state: ['', Validators.required],
      cost: new FormControl('', [
        Validators.required,
        Validators.pattern(/^(\d+|\d+\.\d{1,2})$/)
      ]),
      numberOfTickets: ['', Validators.required],
      beginEndDate: [''],
    });
  }

  get cost() {
    return this.eventRegistrationForm.get('cost');
  }
  get state() {
    return this.eventRegistrationForm.get('state');
  }
  get numberOfTickets() {
    return this.eventRegistrationForm.get('numberOfTickets');
  }

  get today(): NgbDateStruct {
    const now = new Date();
    return {
      year: now.getFullYear(),
      month: now.getMonth() + 1,
      day: now.getDate()
    };
  }

  get beginEndDate() {
    return this.eventRegistrationForm.get('beginEndDate');
  }

  ngOnInit() {
    this.utilService.getStates().subscribe(states => {
      this.states = states;
    });

    this.partnerName = this.route.snapshot.params['partner'];

    const sessionQuoteForm = this.appStateService.getQuoteForm() as EventTicketFormData;

    if (typeof sessionQuoteForm !== 'undefined' && sessionQuoteForm !== null) {
      // Session Storage stores all values as string. The Departure and Return date will need to be convert it to
      // Date objects before the calendar can parse it.
      const eventBeginEndDate = [];
      // Departure Date
      eventBeginEndDate.push(new Date(sessionQuoteForm.eventBeginDate));
      // Return Date
      eventBeginEndDate.push(new Date(sessionQuoteForm.eventEndDate));

      this.eventRegistrationForm.setValue({
        beginEndDate: eventBeginEndDate,
        numberOfTickets: sessionQuoteForm.numberOfTickets,
        state: sessionQuoteForm.state,
        cost: sessionQuoteForm.cost
      });
    }

    this.displayMonths = window.innerWidth >= 768
      ? 2
      : 1;

      this.tomorrow();
  }

  onClose() {
    this.setDateValidation();
    this.utilService.closeModal(this.rangeDatePicker);
  }

  setDateValidation() {
    if (!this.rangeDatePicker.value[0]) {
      this.eventRegistrationForm.controls['beginEndDate'].setErrors(Validators.required);
    }
  }

  onSelect() {
    if (!this.rangeDatePicker.value[1]) {
      setTimeout(() => {
        this.rangeDatePicker.inputfieldViewChild.nativeElement.value = `${
          this.rangeDatePicker.inputFieldValue
        } - ${this.rangeDatePicker.inputFieldValue}`;
      }, 50);
    } else {
      // Close datepicker if end date is selected.
      this.rangeDatePicker.overlayVisible = false;
    }
  }

  public onSubmit() {
    this.errors = [];
    this.setDateValidation();

    if (this.eventRegistrationForm.valid) {
      this.buttonDisabled = true;
      const quoteFormData : EventTicketFormData = {
        state: this.state.value,
        cost: this.cost.value as number,
        numberOfTickets: this.numberOfTickets.value as number,
        eventBeginDate: moment(this.beginEndDate.value[0]).format('MM/DD/YYYY'),
        eventEndDate: moment(this.beginEndDate.value[1]).format('MM/DD/YYYY'),
      }
      this.store.dispatch(
        new GetEventTicketQuote(quoteFormData)
      );

      this.action$.pipe(
        ofType<any>(QuoteActionTypes.GetEventTicketQuoteSuccess),
        map(action => action.payload)
      ).subscribe(q => {
        this.buttonDisabled = false;
        this.ga.formSubmit(ButtonEnum.GetQuote);
        this.appStateService.clearManagePolicyState();
        this.appStateService.clearPurchaseState();
        this.appStateService.setPurchaseSessionActive(true);
        this.appStateService.setQuoteForm(quoteFormData);
        this.appStateService.setQuote(q);
        this.appStateService.broadcastQuote(q);
        this.router.navigateByUrl(`${this.partnerName}/policy/products`);
      });

        this.action$.pipe(
          ofType<any>(QuoteActionTypes.GetEventTicketQuoteFailure),
          map(action => action.payload)
        ).subscribe((err: HttpErrorResponse) => {
          if (err.status === 400) {
            this.errors = this.utilService.getModelStateErrors(
              err.error.modelState
            );
          }
          if (this.errors.length === 0) {
            this.errors.push(AlertMessages.SERVICE_DOWN);
          }
          this.buttonDisabled = false;
        });
    } else {
      this.utilService.validateAllFormFields(this.eventRegistrationForm);
    }
  }

  tomorrow() {
    const d = new Date();
    d.setDate(d.getDate() + 1);
    this.minDateTomorrow = d;
  }

  public reset() {
    this.eventRegistrationForm.reset();
  }
}
