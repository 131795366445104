import { FormControl, FormGroup, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component, OnInit, ViewChild, Input, forwardRef, OnChanges, SimpleChanges } from '@angular/core';
import { ESitesNgbDateParserFormatter } from '../../../quote/services/date-parser-formatter';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { NG_VALIDATORS } from '@angular/forms';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
// import { DayTemplateContext } from '@ng-bootstrap/ng-bootstrap/datepicker/datepicker-day-template-context';
import { TemplateRef } from '@angular/core';

/**
 * Example implementation:
 * <app-date-picker formControlName="departureOrPurchaseDate"></app-date-picker>
 *
 */

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true
    },
    ESitesNgbDateParserFormatter,
    { provide: NgbDateParserFormatter, useClass: ESitesNgbDateParserFormatter }
  ]
})
export class DatePickerComponent implements OnInit, ControlValueAccessor, OnChanges {

  @Input() labelForId: string;
  @Input() _dateVal = ``;
  @Input() isDateOfBirth = false;
  @Input() minDate: NgbDateStruct;
  @Input() maxDate: NgbDateStruct;
  @Input() activeDate: NgbDateStruct;
  @ViewChild(NgbInputDatepicker) ngbInputDatepicker: NgbInputDatepicker;

  public mask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  private _validatePattern = /^\d{2}\/\d{2}\/\d{4}$/;

  displayMonths: number;
  disabled: boolean;
  validationErrors: any;

  onChange: any = () => { };
  onTouched: any = () => { };


  get dateVal() {
    return this._dateVal;
  }

  isActive(date: NgbDateStruct): boolean {
    if (this.activeDate) {
      if (
        date.day === this.activeDate.day
        && date.month === this.activeDate.month
        && date.year === this.activeDate.year
      ) {
        return true;
      } else {
        return false;
      }
    }

  }

  set dateVal(val) {
    // ngbDatePicker will listen for manual changes to an input and can potentially set the value to
    // 'undefined' (string).
    if (
      typeof val !== 'undefined'
      && val !== 'undefined'
    ) {
      const space = val.toString().indexOf('\u2000');
      val = space !== -1
        ? val.substring(0, space)
        : val;
      this._dateVal = val;
      this.onChange(val);
      this.onTouched();
    }
  }

  constructor(
    private _parserFormatter: ESitesNgbDateParserFormatter
  ) { }

  getRequiredBeforeError() {
    return this._parserFormatter.format(this.minDate);
  }

  getRequiredAfterError() {
    return this._parserFormatter.format(this.maxDate);
  }

  ngOnInit() {

    if (this.ngbInputDatepicker) {
      this.ngbInputDatepicker.registerOnChange((val) => {
        if (val) {
          this.dateVal = this._parserFormatter.format(val);
        }
      });
    }

    this.displayMonths = screen.width >= 768
      ? 2
      : 1;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onInputChanged($event) {
    this.dateVal = $event.target.value;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.minDate) {
      this.minDate = changes.minDate.currentValue;
    }
    if (changes.maxDate) {
      this.maxDate = changes.maxDate.currentValue;
    }
  }

  writeValue(value: any) {
    if (typeof (value) !== 'undefined') {
      this.dateVal = this._parserFormatter.format(this._parserFormatter.parse(value));
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  validate(c: FormControl) {
    return this.onBlur();
  }

  onBlur(val?) {
    this.validationErrors = null;
      if (val) {
        // First validate the formatting of the date. MM/DD/YYYY
        const valid = this._validatePattern.test(val);
        if (!valid) {
          this.validationErrors = { 'ngbDate': { pattern: true } };
        } else { // Then leverage ng bootstrap datepicker validate() function to ensure the date is valid.
          if (this.ngbInputDatepicker) {
            this.validationErrors = this.ngbInputDatepicker.validate(new FormControl(this._parserFormatter.parse(val)));
          }
        }
      }
      return this.validationErrors;
  }

}
