import { Component, OnInit, ViewEncapsulation, Input, OnDestroy } from '@angular/core';
import { AgeOrDobEnum } from '../../services/quote.service';
import { UtilService } from '../../../shared/services/util.service';
import { SelectListItem } from '../../../shared/models';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Observable ,  Subject ,  Subscription } from 'rxjs';

import { FormArray } from '@angular/forms/src/model';
import { ESitesNgbDateParserFormatter } from '../../services/date-parser-formatter';
import { NgbDateStruct, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { DestinationEnum } from '../../models/destination.enum';
import { Router, ActivatedRoute } from '@angular/router';
import { AppStateService } from '../../../shared/services/app-state.service';
import { HttpErrorResponse } from '@angular/common/http';
import * as AlertMessages from '../../../shared/constants/alert-messages.constants';
import { ViewChild } from '@angular/core';

import { ToolTipConstants } from '../../../shared/constants/tool-tip-constants';
import { validateAgeOrDobInput } from '../../../shared/validators/validate-ageOrDob-input';
import { Store, Action } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { QuoteState } from '../../reducers';
import {
  GetTravelAgeRatedQuote
} from '../../actions/generate-quote';
import { QuoteActionTypes } from '../../actions/quote';
import { Actions, ofType } from '@ngrx/effects';
import * as moment from 'moment';
import { ButtonEnum } from '../../models/button.enum';
import { GoogleAnalyticsEventsService } from '../../../shared/services/google-analytics-events.service';
import { TravelAgeRatedFormData } from '../../models/form-data.interface';

@Component({
  selector: 'app-travel-age-rated',
  templateUrl: './travel-age-rated.component.html',
  styleUrls: ['./travel-age-rated.component.scss'],
  providers: [
    ESitesNgbDateParserFormatter
  ]
})
export class TravelAgeRatedComponent implements OnInit, OnDestroy {

  tripCostTooltipMessage: string = ToolTipConstants.totalTripCost;
  NUMBER_AGE_BLOCKS = 1;
  MAX_NUM_TRAVELER = 9;
  travelAgeRatedForm: FormGroup;
  states: SelectListItem[];
  errors: String[] = [];
  buttonDisabled = false;
  partnerName: string;
  destinations: Observable<Array<SelectListItem>>;

  destEnum = DestinationEnum;
  destTerm: string;

  ageOrDobEnum = AgeOrDobEnum;
  destSub: Subscription;
  ageDobInputValue: string;
  displayMonths: number;
  minDateTomorrow: Date;
  maxDate: Date;

  @Input() showButton;
  @Input() destinationEnum;
  @Input() quoteButtonText;
  @Input() quoteButtonColor;
  @Input() quoteButtonHoverColor;
  @Input() componentId: string;
  @ViewChild('p') public popover: NgbPopover;
  @ViewChild('rangeDatePicker') rangeDatePicker;

  private destSelection = new Subject<String>();
  destSelectionObservable$: Observable<String> = this.destSelection.asObservable();

  constructor(
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private _dateFormatter: ESitesNgbDateParserFormatter,
    private router: Router,
    private route: ActivatedRoute,
    private appStateService: AppStateService,
    private store: Store<QuoteState>,
    private action$: Actions,
    private ga: GoogleAnalyticsEventsService
  ) {
    this.travelAgeRatedForm = this.formBuilder.group({
      departureReturnDate: [''],
      depositDate: [''],
      totalTripCost: new FormControl('', [
        Validators.required,
        Validators.pattern(/^(\d+|\d+\.\d{1,2})$/)
      ]),
      destination: '',
      state: ['', Validators.required],
      ageArray: this.formBuilder.array([]),
      travelerAgeSelection: AgeOrDobEnum.Age
    });
  }

  ngOnInit() {
    this.utilService.getStates().subscribe(states => {
      this.states = states;
    });

    this.maxDate = new Date();

    this.destinations = this.utilService.getDestinations();

    this.partnerName = this.route.snapshot.params['partner'];

    const sessionQuoteForm = this.appStateService.getQuoteForm() as TravelAgeRatedFormData;

    if (typeof (sessionQuoteForm) !== 'undefined' && sessionQuoteForm !== null) {
      const ageDobArr = [];
      const departureReturnDate = [new Date(sessionQuoteForm.departureDate), new Date(sessionQuoteForm.returnDate)];
      const ageArr = this.normalizeAgeArr(sessionQuoteForm.age);
      for (let i = 0; i < ageArr.length; i++) {
        this.addAge();
        ageDobArr.push(ageArr[i].age);
      }
      this.ageDobInputValue = ageDobArr.join(', ');

      this.travelAgeRatedForm.patchValue({
        departureReturnDate: departureReturnDate,
        destination: sessionQuoteForm.destination,
        state: sessionQuoteForm.state,
        totalTripCost: sessionQuoteForm.cost,
        ageArray: ageArr,
        depositDate: new Date(sessionQuoteForm.depositDate),
        travelerAgeSelection: sessionQuoteForm.ageOrDob
      });
    } else {
      for (let i = 0; i < this.NUMBER_AGE_BLOCKS; i++) {
        this.addAge();
      }
    }

    this.displayMonths = window.innerWidth >= 768
    ? 2
    : 1;

    this.tomorrow();
  }

  normalizeAgeArr(ageArr: any[]): any[] {
    if (ageArr.length == 0) return ageArr;
    if (ageArr[0].age) return ageArr;
    const normalized = [];
    for (const age of ageArr) {
      normalized.push({
        age: age
      })
    }
    return normalized;
  }

  ngOnDestroy() {
    if (this.destSub) {
      this.destSub.unsubscribe();
    }
  }

  onClose() {
    this.setDateValidation();
    this.utilService.closeModal(this.rangeDatePicker);
  }

  onSelect() {
    // Close datepicker if end date is selected.
    if (this.rangeDatePicker.value[1]) {
      this.rangeDatePicker.overlayVisible = false;
    }
  }

  // Validate p-calendar. This is to avoid validation in between the time a date is selected and passing it to the model
  setDateValidation() {
    if (!this.rangeDatePicker.value[0]) {
      this.travelAgeRatedForm.controls['departureReturnDate'].setErrors(Validators.required);
    }

    if (this.depositDate.value === '') {
      this.travelAgeRatedForm.controls['depositDate'].setErrors(Validators.required);
    }

  }

  onClosePopover(event) {
    this.popover.close();
  }

  onSubmit() {
    this.errors = [];
    this.setDateValidation();

    if (this.travelAgeRatedForm.valid) {
      this.buttonDisabled = true;
      const quoteFormData : TravelAgeRatedFormData = {
        state: this.state.value,
        cost: Number(this.totalTripCost.value),
        destination: this.destination.value,
        departureDate: moment(this.departureReturnDate.value[0]).format('MM/DD/YYYY'),
        returnDate: moment(this.departureReturnDate.value[1]).format('MM/DD/YYYY'),
        depositDate: moment(this.depositDate.value).format('MM/DD/YYYY'),
        age: this.travelerAgeSelection.value === AgeOrDobEnum.Age ? this.parseAges(this.ageArray) : this.parseDobs(this.ageArray),
        ageOrDob: this.travelerAgeSelection.value
      }
      this.store.dispatch(
        new GetTravelAgeRatedQuote(quoteFormData)
      );

      this.action$.pipe(
        ofType<any>(QuoteActionTypes.GetTravelAgeRatedQuoteSuccess),
        map(action => action.payload)
      ).subscribe(q => {
          this.buttonDisabled = false;
          this.ga.formSubmit(ButtonEnum.GetQuote);
          this.appStateService.clearManagePolicyState();
          this.appStateService.clearPurchaseState();
          this.appStateService.setPurchaseSessionActive(true);
          this.appStateService.setQuoteForm(quoteFormData);
          this.appStateService.setQuote(q);
          this.appStateService.broadcastQuote(q);
          this.router.navigateByUrl(`${this.partnerName}/policy/products`);
        });

      this.action$.pipe(
        ofType<any>(QuoteActionTypes.GetTravelAgeRatedQuoteFailure),
        map(action => action.payload)
      ) .subscribe((err: HttpErrorResponse) => {
          if (err.status === 400) {
            this.errors = this.utilService.getModelStateErrors(err.error.modelState);
          }
          if (this.errors.length === 0) {
            this.errors.push(AlertMessages.SERVICE_DOWN);
          }
          this.buttonDisabled = false;
        });
    } else {
      this.utilService.validateAllFormFields(this.travelAgeRatedForm);
    }

  }

  destSelected($event) {
    const value = $event ? $event.desc : '';
    this.destSelection.next(value);
  }

  // To display the traveler's age or date of birth in the input field
  displayAgeDob(array: FormArray) {
    const arr = [];
    const valueArray = array.value as Array<any>;
    valueArray.forEach(element => {
      if (element.age !== null) {
        arr.push(element.age);
      }
    });
    return arr.join(', ');
  }

  private parseAges(array: FormArray): number[] {
    const ages = [];
    const valueArray = array.value as Array<any>;
    valueArray.forEach(element => {
      if (element.age !== null) {
        ages.push(Number(element.age));
      }
    });
    this.ageDobInputValue = ages.join(', ');
    return ages;
  }

  private parseDobs(array: FormArray): string[] {
    const dobs = [];
    const valueArray = array.value as Array<any>;
    valueArray.forEach(element => {
      if (element.age !== null) {
        dobs.push(element.age);
      }
    });
    this.ageDobInputValue = dobs.join(', ');
    return dobs;
  }

  addAge() {
    const arrayControl = this.ageArray;
    let newControl: FormControl;
    if (arrayControl.value.length === 0) {
      newControl = new FormControl('', validateAgeOrDobInput);
    } else {
      newControl = new FormControl();
    }
    const newGroup = this.formBuilder.group({
      age: newControl
    });

    arrayControl.push(newGroup);
  }

  canAddTravelers() {
    return this.ageArray.value.length < this.MAX_NUM_TRAVELER;
  }

  tomorrow() {
    const d = new Date();
    d.setDate(d.getDate() + 1);
    this.minDateTomorrow = d;
  }

  removeAge(i: number) {
    this.ageArray.removeAt(i);
    event.stopPropagation();
  }

  get today(): NgbDateStruct { return this.utilService.getToday(); }
  get yesterday(): NgbDateStruct { return this.utilService.getYesterday(); }

  ageDobSwitch() {
    this.ageArray.controls.forEach(control => {
      control.reset();
      control.markAsUntouched();
    });
  }

  get destination() { return this.travelAgeRatedForm.get('destination') }
  get totalTripCost() { return this.travelAgeRatedForm.get('totalTripCost') }
  get departureReturnDate() {
    return this.travelAgeRatedForm.get('departureReturnDate');
  }
  get state() { return this.travelAgeRatedForm.get('state') }
  get ageArray(): FormArray { return this.travelAgeRatedForm.get('ageArray') as FormArray }
  get depositDate() { return this.travelAgeRatedForm.get('depositDate') }
  get travelerAgeSelection() { return this.travelAgeRatedForm.get('travelerAgeSelection') }

}
