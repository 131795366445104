import { SimpleChanges } from '@angular/core/src/metadata/lifecycle_hooks';
import { Component, OnInit, ViewEncapsulation, Input, OnChanges, OnDestroy } from '@angular/core';
import { Observable ,  Subscription } from 'rxjs';

@Component({
  selector: 'app-form-validation',
  templateUrl: './form-validation.component.html',
  styleUrls: ['./form-validation.component.scss']
})
export class FormValidationComponent implements OnInit, OnChanges, OnDestroy {

  @Input() errorMessages: Array<string>;
  @Input('submit') submitEvent$: Observable<void>;

  private _show;
  get show() { return this._show };
  subscription: Subscription;

  constructor() { }

  ngOnInit() {
    if (this.submitEvent$) {
      this.subscription = this.submitEvent$.subscribe(next => this.close());
    }
  }

  close() {
    this._show = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.errorMessages && changes.errorMessages.currentValue) {
      this.errorMessages = changes.errorMessages.currentValue;
      if (this.errorMessages.length > 0) {
        this._show = true;
      } else {
        this._show = false;
      }
    }
    if (changes.submitEvent$ && changes.submitEvent$.currentValue) {
      this.submitEvent$ = changes.submitEvent$.currentValue;
      this.subscription = this.submitEvent$.subscribe(next => this.close());
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
