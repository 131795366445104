
import {map, mergeMap} from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable ,  Subject, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from './../../../environments/environment';
import {
  Policy,
  PolicyQueryResult,
  PolicyListing,
  EmailOrPolicySelectionsEnum,
  DepartureOrPurchaseDateSelectionEnum,
  PolicyEmail,
  Report,
  Booking
} from '../models';
import { AppStateService } from '../../shared/services/app-state.service';
import { NetworkService } from '../../shared/services/network.service';
import * as _ from 'lodash';
import { File } from '../../shared/models';
import { Quote } from '../../quote/models/quote.interface';
import { CryptoService } from '../../shared/services/crypto.service';

declare var jQuery: any;
declare var $: any;

@Injectable()
export class PolicyService extends NetworkService {

  // Notifies billing information component that a change on the modify form has occurred.
  private modifyFormChange = new Subject<void>();

  public modifyFormChangeObservable$ = this.modifyFormChange.asObservable();

  private policyFormSubmit = new Subject<void>();

  public policyFormSubmitObservable$ = this.policyFormSubmit.asObservable();

  constructor(
    private http: HttpClient,
    private appStateService: AppStateService,
    private cryptoService: CryptoService,
    injector: Injector
  ) {
    super(injector);
  }

  searchPolicy(emailOrPolicyValue: string, emailOrPolicy: EmailOrPolicySelectionsEnum,
    departureOrPurchaseValue: string, departureOrPurchase: DepartureOrPurchaseDateSelectionEnum,
    zipCode: string, isPolicyNumEncrypted?: string, sys?: string | undefined): Observable<any> {


    let params = new HttpParams();
    switch (Number(emailOrPolicy)) {
      case EmailOrPolicySelectionsEnum.EmailAddress:
        params = params.append('email', this.cryptoService.CryptoData(emailOrPolicyValue));
        break;
      case EmailOrPolicySelectionsEnum.PolicyNumber:
        params = params.append('policyNumber', this.cryptoService.CryptoData(emailOrPolicyValue));
        break;
    }

    switch (Number(departureOrPurchase)) {
      case DepartureOrPurchaseDateSelectionEnum.DepartureDate:
        params = params.append('startDate',departureOrPurchaseValue);
        break;
      case DepartureOrPurchaseDateSelectionEnum.PurchaseDate:
        params = params.append('purchaseDate', departureOrPurchaseValue);
        break;
    }

    params = params.append('zipCode', zipCode);

    if (isPolicyNumEncrypted) {
      params = params.append('isPolicyNumEncrypted', isPolicyNumEncrypted);
    }

    if (sys && sys !== undefined) {
      params = params.append('sys', sys);
    }

    return this.request(this.http.get<PolicyQueryResult>(`${environment.apiGatewayUrl}/policy/search`, { params: params })).pipe(
      map( (pqr: PolicyQueryResult) => {
        pqr.policies.sort((a: PolicyListing, b: PolicyListing) => {
          const datea = new Date(a.purchaseDate);
          const dateb = new Date(b.purchaseDate);
          return dateb.getTime() - datea.getTime();
        });
        return pqr;
      }), catchError(e => this.handleError(e)));
  }

  retrieveProduct(bookingId: number, deepLinkToken?: string): Observable<Quote> {

    let headers = {};

    if (deepLinkToken) {
      headers = {
        'Token': deepLinkToken
      }
    }

    return this.request(this.http.get(`${environment.apiGatewayUrl}/quote?bookingId=${bookingId}`, { headers: headers})).pipe(
      map( (pqr) => {
        return pqr;
      }), catchError(e => this.handleError(e)));
  }

  getPolicy(pl: PolicyListing): Observable<Policy> {
    return this._getPolicy(pl, 'ReadOnly');
  }

  getModifyPolicy(pl: PolicyListing): Observable<Policy> {
    return this._getPolicy(pl, 'Modify');
  }

  getEditFlightsPolicy(pl: PolicyListing): Observable<Policy> {
    return this._getPolicy(pl, 'EditFlights');
  }

  viewPrintPolicy(bookingId: number): Observable<Blob> {
    return this.request(this.http.request<Blob>(`put`,
    `${environment.apiGatewayUrl}/policy/receipt?bookingId=${bookingId}&docRequestType=View`,
      {
        body: {},
        responseType: 'blob' as 'json',
        headers: {
          'Accept': 'application/pdf'
        }
      }));
  }

  private _getPolicy(pl: PolicyListing, action: string) {
    const params = new HttpParams().append('policyAction', action);
    return this.request(this.http.get<Policy>(this.getPolicyResourcePath(pl), { params: params })).pipe(
      map(p => {
        p.policyListing = pl;
        return p;
      }), catchError(e => this.handleError(e)));
  }

  getPolicyDocumentBlob(archiveLocation: string): Observable<File> {
    const params = new HttpParams().append('path', archiveLocation);
    const headers = new HttpHeaders();
    return this.request(this.http.get<File>(`${environment.apiGatewayUrl}/fulfillment`, { params: params }));
  }

  cancelPolicy(p: Policy): Observable<Policy> {
    return this.request(this.http.request<Policy>('delete', this.getPolicyResourcePath(p.policyListing), { body: p }));
  }

  getPrice(p: Policy): Observable<Booking> {
    return this.request(this.http.put<Booking>(`${environment.apiGatewayUrl}/policy/${this.cryptoService.CryptoData(p.policyListing.policyNumber)}/price`, p)).pipe(
      catchError(e => this.handleError(e))
    );

  }

  modifyPolicy(p: Policy): Observable<Policy> {
    return this.request(this.http.put<Policy>(this.getPolicyResourcePath(p.policyListing),
      p)).pipe(
        catchError(e => this.handleError(e))
      );
  }

  editFlights(p: Policy): Observable<Policy> {
    return this.request(this.http.put(`${this.getPolicyResourcePath(p.policyListing)}/flights`,
      p)).pipe(
        catchError(e => this.handleError(e))
      );
  }

  purchasePolicy(p: Policy): Observable<Policy> {
    return this.request(this.http.post(`${environment.apiGatewayUrl}/policy`, p));
  }

  emailPolicy(policyNumber: string, recipientName: string,
    primaryEmail: string, source: string, secondaryEmail?: string): Observable<boolean> {

    const pe = <PolicyEmail>{
      toName: recipientName,
      emails: [primaryEmail]
    };

    if (secondaryEmail) {
      pe.emails.push(secondaryEmail);
    }
    policyNumber = (this.cryptoService.CryptoData(policyNumber));
    var re = new RegExp('%', 'g');
    policyNumber = policyNumber.replace(re, '@');

    return this.request(this.http.put<Report>(`${environment.apiGatewayUrl}/policy/${policyNumber}/email?source=${source}`, pe))
      .pipe(
        mergeMap(r => {
          return of(r.isEmailSendSuccessful);
        }), catchError(e => this.handleError(e))
      );
  }

  retrieveQuote(bookingId: number, productId: number, priceOffered: number, offerType: string): Observable<Booking> {
    return this.request(this.http.get<Booking>(
      `${environment.apiGatewayUrl}/quote/${bookingId}/retrieve?productId=${productId}&price=${priceOffered}&offerType=${offerType}`));
  }

  private getPolicyResourcePath(pl: PolicyListing): string {

    return `${environment.apiGatewayUrl}/policy/${this.cryptoService.CryptoData(pl.policyNumber)}/${encodeURIComponent(pl.departureDate)}/${encodeURIComponent(pl.purchaseDate)}`;
  }

  getCreditCardCodeString(): string {

    let ccCodeString = '';

    const cardData = $('#cardNumberJs').validateCreditCard();

    if (cardData.card_type !== null && typeof cardData.card_type !== 'undefined') {
      switch (_.toLower(cardData.card_type.name)) {
        case 'visa':
          ccCodeString = 'VI';
          break;
        case 'amex':
          ccCodeString = 'AX';
          break;
        case 'discover':
          ccCodeString = 'DS';
          break;
        case 'mastercard':
          ccCodeString = 'MC';
          break;
        case 'diners_club_carte_blanche':
          ccCodeString = 'DC';
          break;
        case 'diners_club_international':
          ccCodeString = 'DC';
      }
    }

    return ccCodeString;
  }

  getQuote(b: Booking): Observable<Booking> {
    return this.request(this.http.post<Booking>(`${environment.apiGatewayUrl}/quote`, b));
  }

  broadcastModifyFormChange() {
    this.modifyFormChange.next();
  }

  broadcastPolicyFormSubmit() {
    this.policyFormSubmit.next();
  }

  getFileClaimUrl(pl: PolicyListing) {
    return `${environment.claimsUrl}?pol=${pl.policyCode}&purch_date=${pl.purchaseDate}`;
  }

  getProductTCUrl(psProductId: string, state: string) {
    return `${environment.tcUrl}?classic=true&productid=${psProductId}&state=${state}`;
  }

  getPurchaseAgreementUrl(): string {
    return environment.purchaseAgreementUrl;
  }

}
