import { NavigationEnum } from "../../components/navbar/navigation.enum";
import * as _ from 'lodash';

export interface NavigationLink {
    type: NavigationEnum;
    name: string;
    route: string;
    isExternal: boolean;
    hovering: boolean;
    modifiable: boolean;
    active: boolean;
}

const homeLink: NavigationLink = {
    type: NavigationEnum.Home,
    name: `Home`,
    route: `home`,
    isExternal: false,
    hovering: false,
    modifiable: false,
    active: true
};
const myPoliciesLink: NavigationLink = {
    type: NavigationEnum.MyPolicies,
    name: `my policies`,
    route: `policy`,
    isExternal: false,
    hovering: false,
    modifiable: true,
    active: false
}
const coverageOptionsLink: NavigationLink = {
    type: NavigationEnum.CoverageOptions,
    name: `Coverage Options`,
    route: `coverage-options`,
    isExternal: false,
    hovering: false,
    modifiable: true,
    active: false
};
const coverageAlertsLink: NavigationLink = {
    type: NavigationEnum.CoverageAlerts,
    name: `Coverage Alerts`,
    route: `https://azcontent.us/coverage-alerts`,
    isExternal: true,
    hovering: false,
    modifiable: false,
    active: true
};
const faqLink: NavigationLink = {
    type: NavigationEnum.FAQ,
    name: `FAQ`,
    route: `faq`,
    isExternal: false,
    hovering: false,
    modifiable: false,
    active: true
};
const aboutUsLink: NavigationLink = {
    type: NavigationEnum.AboutUs,
    name: `About Us`,
    route: `about-us`,
    isExternal: false,
    hovering: false,
    modifiable: false,
    active: true
};

export function getNavbarLinks(): Array<NavigationLink> {
    return [
        homeLink,
        myPoliciesLink,
        coverageOptionsLink,
        coverageAlertsLink,
        faqLink,
        aboutUsLink
    ];
}

export function getFooterLinks(): Array<NavigationLink> {
    return [
        homeLink,
        coverageOptionsLink,
        coverageAlertsLink,
        faqLink,
        aboutUsLink
    ];
}