import { ReportActionTypes, ReportActionUnion } from './../actions/report';
import { createSelector } from '@ngrx/store';
import { Report } from '../models';

export interface State {
  report: Report;
  error: string | null;
}

const initialState: State = {
  report: null,
  error: null
};

export function reducer(
  state = initialState,
  action: ReportActionUnion
): State {
  switch (action.type) {
    case ReportActionTypes.EmailQuoteSuccess: {
      return {
        ...state,
        report: action.payload
      };
    }

    case ReportActionTypes.EmailQuoteFailure: {
      return {
        ...state,
        error: action.payload
      };
    }

    case ReportActionTypes.EmailReceiptSuccess: {
      return {
        ...state,
        report: action.payload
      };
    }

    case ReportActionTypes.EmailReceiptFailure: {
      return {
        ...state,
        error: action.payload
      };
    }

    default: {
      return state;
    }
  }
}
