import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit, AfterViewInit {

  @ViewChild('calendar') calendar: ElementRef;

  constructor(private renderer: Renderer2) { }

  ngOnInit() {}

  ngAfterViewInit() {

    const calendarEl = this.calendar.nativeElement.firstElementChild;

    // This is a fix to prevent backspace being pressed and reloading the page in IE. 
    // This will only affect the calendar.
    this.renderer.listen(calendarEl, 'keydown', (event: KeyboardEvent) => {
      if (event.keyCode === 8) {
        event.preventDefault();
      }
    });
  }

}
