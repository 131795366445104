<div class="home-container">
    <app-hero-image  [style.minHeight.px]="height" (getImgHeight)="getImgHeight($event)"  [nestled]="widgetOnHero" class="hero-img-home" [class.nestled-img-js]="widgetOnHero" [src]="heroImageUrl"></app-hero-image>
    
    <div *ngIf="widgetOnHero" [class.col-md-12]="widgetOnHero" class="content-container--why-buy d-flex align-items-center">
        <app-content-block *ngIf="firstContentBlock | async as cb" [content]="cb"></app-content-block>
    </div>
<br>
    <div  class="alert-warning">
        <div class="container">
            An unexpected error has occurred, please try again later   
        </div>
      </div>

    </div>