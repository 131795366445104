import { Action } from '@ngrx/store';
import { createActionType } from '../../shared/utils';
import { GenerateQuote } from '../models/generate-quote.interface';
import { AgeOrDobEnum } from '../services/quote.service';
import {
  CarRentalFormData,
  TravelFormData,
  AnnualWidgetFormData,
  TravelAgeRatedFormData,
  RailFormData,
  SessionSeasonFormData,
  EventTicketFormData
} from '../models/form-data.interface';

export enum GenerateQuoteActionTypes {
  GetCarRentalQuote = '[GenerateQuote] Car Rental',
  GetTravelQuote = '[GenerateQuote] Non age Rated Travel',
  GetTravelAgeRatedQuote = '[GenerateQuote] Age Rated Travel',
  GetEventTicketQuote = '[GenerateQuote] Event Ticket',
  GetAnnualTravelQuote = '[GenerateQuote] Annual Travel',
  GetRailQuote = '[GenerateQuote] Rail',
  GetSessionSeasonQuote = '[GenerateQuote] Session and Season'
}

export class GetCarRentalQuote implements Action {
  readonly type = GenerateQuoteActionTypes.GetCarRentalQuote;

  constructor(public payload: CarRentalFormData) {}
}

export class GetTravelQuote implements Action {
  readonly type = GenerateQuoteActionTypes.GetTravelQuote;

  constructor(public payload: TravelFormData) {}
}

export class GetAnnualTravelQuote implements Action {
  readonly type = GenerateQuoteActionTypes.GetAnnualTravelQuote;

  constructor(public payload: AnnualWidgetFormData) {}
}

export class GetTravelAgeRatedQuote implements Action {
  readonly type = GenerateQuoteActionTypes.GetTravelAgeRatedQuote;

  constructor(public payload: TravelAgeRatedFormData) {}
}

export class GetEventTicketQuote implements Action {
  readonly type = GenerateQuoteActionTypes.GetEventTicketQuote;

  constructor(public payload: EventTicketFormData) {}
}

export class GetRailQuote implements Action {
  readonly type = GenerateQuoteActionTypes.GetRailQuote;

  constructor(public payload: RailFormData) {}
}

export class GetSessionSeasonQuote implements Action {
  readonly type = GenerateQuoteActionTypes.GetSessionSeasonQuote;

  constructor(public payload: SessionSeasonFormData) {}
}

export type GeneratesQuoteActionUnion =
  | GetCarRentalQuote
  | GetTravelQuote
  | GetTravelAgeRatedQuote
  | GetEventTicketQuote
  | GetAnnualTravelQuote
  | GetRailQuote
  | GetSessionSeasonQuote;
