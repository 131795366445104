import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { AppStateService } from '../services/app-state.service';
import { UtilService } from '../services/util.service';
import { Observable } from 'rxjs';
import { tap, mergeMap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private appStateService: AppStateService, private injector: Injector) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const utilService = this.injector.get(UtilService);
        if (req.headers.get('shouldAuth') === 'false' || req.headers.get('Token')) {
            const noHeadReq = req.clone({ headers: req.headers.delete('shouldAuth') });
            return next.handle(noHeadReq);
        }
        if (this.appStateService.getPartnerToken()) {
            const authReq = req.clone({ headers: req.headers.set('Token', this.appStateService.getPartnerToken()) });
            return next.handle(authReq);
        } else {
            return utilService.getToken(this.appStateService.getPartnerConfig().accam).pipe(
                tap(tok => {
                    this.appStateService.setPartnerToken(tok);
                }),
                mergeMap(token => {
                    const authReq = req.clone({ headers: req.headers.set('Token', token ? token : '') });
                    return next.handle(authReq);
                })
            );
        }
    }
}
