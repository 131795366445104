export class EmailQuoteRequest {
    docRequestType: string;
    scOffer: string;
    programProductId: string;
    reportDocumentType: string;
    emailInput: EmailInput;
    partnerAddress: string;
    partnerDisplayName: string;
    partnerLogoUrl: string;
    siteId: string;
}

export class EmailInput {
    toEmailAddressType: any;
    toEmailAddress: string;
    toName: string;
    fromEmailAddress: string;
    fromName: string;
    ccToFromAddress: boolean;
    subject: string;
    messageFromUser: string;
    messageFromSystem: string;
    webPageURL: string;
}
