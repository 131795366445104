import { ToolTipConstants } from './../../../shared/constants/tool-tip-constants';
import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { UtilService } from '../../../shared/services/util.service';
import { SelectListItem } from '../../../shared/models';
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators
} from '@angular/forms';
import { DestinationEnum } from '../../models/destination.enum';
import { Observable ,  Subscription ,  Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { Router, ActivatedRoute } from '@angular/router';
import { AppStateService } from '../../../shared/services/app-state.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ESitesNgbDateParserFormatter } from '../../services/date-parser-formatter';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct';
import * as AlertMessages from '../../../shared/constants/alert-messages.constants';
import { Store } from '@ngrx/store';
import { QuoteState } from '../../reducers';
import {
  GetTravelQuote
} from '../../actions/generate-quote';
import { QuoteActionTypes } from '../../actions/quote';
import { Actions, ofType } from '@ngrx/effects';
import * as moment from 'moment';
import { ButtonEnum } from '../../models/button.enum';
import { GoogleAnalyticsEventsService } from '../../../shared/services/google-analytics-events.service';
import { TravelFormData } from '../../models/form-data.interface';
@Component({
  selector: 'app-travel-non-age-rated',
  templateUrl: './travel-non-age-rated.component.html',
  styleUrls: ['./travel-non-age-rated.component.scss'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: ESitesNgbDateParserFormatter },
    ESitesNgbDateParserFormatter
  ]
})
export class TravelNonAgeRatedComponent implements OnInit, OnDestroy {
  MAX_NUM_TRAVELERS = 9;
  travelNonAgeRatedForm: FormGroup;
  states: SelectListItem[];
  errors: String[] = [];
  buttonDisabled = false;
  partnerName: string;
  tripCostTooltipMessage: string = ToolTipConstants.totalTripCost;

  destEnum = DestinationEnum;
  destSub: Subscription;
  quoteSub: Subscription;
  destTerm: string;
  destinations: Observable<Array<SelectListItem>>;
  minDateTomorrow: Date;
  displayMonths: number;
  departureDate: Date;
  returnDate: Date;

  @Input() destinationEnum;
  @Input() quoteButtonText;
  @Input() quoteButtonColor;
  @Input() quoteButtonHoverColor;
  @Input() componentId: string;
  @ViewChild('rangeDatePicker') rangeDatePicker;

  private destSelection = new Subject<String>();
  destSelectionObservable$: Observable<
    String
  > = this.destSelection.asObservable();

  constructor(
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private _dateFormatter: ESitesNgbDateParserFormatter,
    private router: Router,
    private route: ActivatedRoute,
    private appStateService: AppStateService,
    private store: Store<QuoteState>,
    private action$: Actions,
    private ga: GoogleAnalyticsEventsService
  ) {
    this.travelNonAgeRatedForm = this.formBuilder.group({
      departureReturnDate: [''],
      totalTripCost: new FormControl('', [
        Validators.required,
        Validators.pattern(/^(\d+|\d+\.\d{1,2})$/)
      ]),
      destination: '',
      state: ['', Validators.required],
      numTravelers: [1, Validators.required],
      frequentFlyer: [false, Validators.required]
    });
  }

  ngOnInit() {
    this.utilService.getStates().subscribe(states => {
      this.states = states;
    });

    this.destinations = this.utilService.getDestinations();

    this.partnerName = this.route.snapshot.params['partner'];

    const sessionQuoteForm = this.appStateService.getQuoteForm() as TravelFormData;

    if (typeof sessionQuoteForm !== 'undefined' && sessionQuoteForm !== null) {
      // Session Storage stores all values as string. The Departure and Return date will need to be convert it to
      // Date objects before the calendar can parse it.
      const departureReturnDate = [new Date(sessionQuoteForm.departureDate), new Date(sessionQuoteForm.returnDate)];

      this.travelNonAgeRatedForm.patchValue({
        departureReturnDate: departureReturnDate,
        destination: sessionQuoteForm.destination,
        frequentFlyer: sessionQuoteForm.frequentFlyer,
        numTravelers: sessionQuoteForm.numTravelers,
        state: sessionQuoteForm.state,
        totalTripCost: sessionQuoteForm.cost
      });
    }

    this.displayMonths = window.innerWidth >= 768
      ? 2
      : 1;

    this.tomorrow();
  }

  
  onClose() {
    this.setDateValidation();
    this.utilService.closeModal(this.rangeDatePicker);
  }

  onSelect() {
    // Close datepicker if end date is selected.
    if (this.rangeDatePicker.value[1]) {
      this.rangeDatePicker.overlayVisible = false;
    }
  }

  setDateValidation() {
    if (!this.rangeDatePicker.value[0]) {
      this.travelNonAgeRatedForm.controls['departureReturnDate'].setErrors(Validators.required);
    }
  }

  get numTravelersArray(): number[] {
    const arr = [];
    let i = 1;
    while (arr.push(i++) < this.MAX_NUM_TRAVELERS) {}
    return arr;
  }

  ngOnDestroy() {
    if (this.destSub) {
      this.destSub.unsubscribe();
    }
      if (this.quoteSub) {
      this.quoteSub.unsubscribe();
    }
  }
    onSubmit() {
    this.errors = [];
    this.setDateValidation();

    if (this.travelNonAgeRatedForm.valid) {
      this.buttonDisabled = true;
      const quoteFormData = <TravelFormData>{
        state: this.state.value,
        cost: this.totalTripCost.value as number,
        destination: this.destination.value,
        departureDate: moment(this.departureReturnDate.value[0]).format('MM/DD/YYYY'),
        returnDate: moment(this.departureReturnDate.value[1]).format('MM/DD/YYYY'),
        numTravelers: this.numTravelers.value as number,
        frequentFlyer: this.frequentFlyer.value as boolean
      }
      this.store.dispatch(
        new GetTravelQuote(quoteFormData)
      );
      if (!this.quoteSub) {
        this.quoteSub = this.action$.pipe(
          ofType<any>(QuoteActionTypes.GetTravelQuoteSuccess),
          map(action => action.payload)
        ).subscribe(q => {
          this.buttonDisabled = false;
          this.ga.formSubmit(ButtonEnum.GetQuote);
          this.appStateService.clearManagePolicyState();
          this.appStateService.clearPurchaseState();
          this.appStateService.setPurchaseSessionActive(true);
          this.appStateService.setQuoteForm(quoteFormData);
          this.appStateService.setQuote(q);
          this.appStateService.broadcastQuote(q);
          this.router.navigateByUrl(`${this.partnerName}/policy/products`);
        });
      }
      this.action$.pipe(
          ofType<any>(QuoteActionTypes.GetTravelQuoteFailure),
          map(action => action.payload)
        ).subscribe((err: HttpErrorResponse) => {
          if (err.status === 400) {
            this.errors = this.utilService.getModelStateErrors(
              err.error.modelState
            );
          }
          if (this.errors.length === 0) {
            this.errors.push(AlertMessages.SERVICE_DOWN);
          }
          this.buttonDisabled = false;
        });

      this.action$.pipe(
        ofType<any>(QuoteActionTypes.GetCarRentalQuoteFailure),
        map(action => action.payload)
      ).subscribe((err: HttpErrorResponse) => {
          if (err.status === 400) {
            this.errors = this.utilService.getModelStateErrors(
              err.error.modelState
            );
          }
          if (this.errors.length === 0) {
            this.errors.push(AlertMessages.SERVICE_DOWN);
          }
          this.buttonDisabled = false;
        });
    } else {
      this.utilService.validateAllFormFields(this.travelNonAgeRatedForm);
    }
  }

  tomorrow() {
    const d = new Date();
    d.setDate(d.getDate() + 1);
    this.minDateTomorrow = d;
  }

  destSelected($event) {
    const value = $event ? $event.desc : '';
    this.destSelection.next(value);
  }

  get destination() {
    return this.travelNonAgeRatedForm.get('destination');
  }
  get totalTripCost() {
    return this.travelNonAgeRatedForm.get('totalTripCost');
  }
  get departureReturnDate() {
    return this.travelNonAgeRatedForm.get('departureReturnDate');
  }
  get state() {
    return this.travelNonAgeRatedForm.get('state');
  }
  get numTravelers() {
    return this.travelNonAgeRatedForm.get('numTravelers');
  }
  get frequentFlyer() {
    return this.travelNonAgeRatedForm.get('frequentFlyer');
  }
  get yesterday(): NgbDateStruct {
    return this.utilService.getYesterday();
  }
}
