<header>
  <div class="logo-container d-none d-md-flex">
    <div>
      <a *ngIf="logoHref" [href]="logoHref" target="_blank">
        <img class="partner-logo" [src]="logoUrl" [alt]="partnerName + 'Logo'">
      </a>
      <img *ngIf="!logoHref && !!logoUrl" class="partner-logo" [src]="logoUrl" [attr.alt]="partnerName + 'Logo'">
    </div>
    <div class="logo-container__allianz">
      <div class="logo-container">
        <small>Provided by</small>
        <a [routerLink]="[partnerName, 'home']">
          <img class="logo" alt="Provided by Allianz" [src]="agaLogoUrl">
        </a>
      </div>
      <app-telephone [phoneNumber]="phoneNumber" *ngIf="showPhone"></app-telephone>
    </div>
  </div>
</header>
