<form [formGroup]="annualForm" (ngSubmit)="onSubmit()" class="form" novalidate>

  <div class="form-row">
    <div class="col-md-6">
      <div class="form-group">
        <label for="state">State of Residence</label>
        <select id="state" class="form-control form-control-sm" formControlName="state">
          <option value="" selected>Choose a state</option>
          <option *ngFor="let state of states" value="{{state.code}}">{{state.code}}-{{state.desc}}</option>
        </select>
        <app-input-validation [inputFormControl]="state"></app-input-validation>
      </div>
    </div>

    <div class="col-md-6">
      <div class="form-group">
        <label for="numTravelers">Number of Travelers</label>
        <div class="input-group input-group-sm">
          <select id="numTravelers" class="form-control form-control-sm" formControlName="numTravelers">
            <option *ngFor="let i of numTravelersArray" value="{{i}}">{{i}}</option>
          </select>
        </div>
      </div>
    </div>
  </div>

  <div class="form-row destination">
    <div class="col-md-6">
      <div [ngSwitch]="destinationEnum">
        <div *ngSwitchCase="destEnum.Full" class="form-group">
          <label for="destination">Destination
            <small class="text-muted">
              Optional
            </small>
          </label>
          <ng-select class="custom" (change)="destSelected($event)" [items]="destinations | async" bindValue="desc" bindLabel="desc" formControlName="destination"></ng-select>
          <app-input-validation [inputFormControl]="destination"></app-input-validation>
        </div>

        <div *ngSwitchCase="destEnum.IntlDom" class="form-group">
          <label for="destination">Destination
            <small class="text-muted">
              Optional
            </small>
          </label>
          <select id="destination" class="form-control form-control-sm" formControlName="destination">
            <option value="domestic">Domestic</option>
            <option value="international">International</option>
          </select>
          <app-input-validation [inputFormControl]="destination"></app-input-validation>
        </div>
      </div>
    </div>
  </div>

  <div class="form-row">
    <div class="col-md-12">
      <app-dynamic-button [compId]="componentId + 'annual-btn'" type="submit" class="pull-right" [disabled]="buttonDisabled" [buttonText]="quoteButtonText" [buttonHoverColor]="quoteButtonHoverColor"
        [buttonBackgroundColor]="quoteButtonColor"></app-dynamic-button>
    </div>
  </div>

  <app-form-validation [errorMessages]="errors"></app-form-validation>
</form>