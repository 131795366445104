import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, RoutesRecognized, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { filter,pairwise } from "rxjs/operators";
import { AppStateService } from "../services/app-state.service";

@Injectable()
export class CanRedirectGuard implements CanActivate {

    constructor(private appStateService: AppStateService,
        private router: Router){}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        const parsedURL = new URL(window.location.href);
        const baseURL = parsedURL.origin;
        window.location.href = baseURL+localStorage.getItem("PARTNER_URL_PATH");
        return true;
    }
    
}