
import {tap} from 'rxjs/operators';
import { environment } from './../../../environments/environment';
import { TimeoutService } from './../services/timeout.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Site } from './../models/site.interface';
import { ContentManagementService } from './../services/content-management.service';
import { Observable } from 'rxjs';



import { AppStateService } from './../services/app-state.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRoute, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Location } from '@angular/common';

@Injectable()
export class CanActivateGuard implements CanActivate {

  protected _partnerParameterName;
  protected DEFAULT_PARTNER = 'allianz';

  constructor(
    private route: ActivatedRoute,
    protected router: Router,
    private location: Location,
    private contentManagementService: ContentManagementService,
    protected appStateService: AppStateService,
    private timeoutService: TimeoutService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    const partnerParam = route.queryParams.partner;
    const policyNumber = route.queryParams.pol;
    const purchDate = route.queryParams.purch_date;
    const sys = route.queryParams.sys;


    if (partnerParam) {
      this._partnerParameterName = route.queryParams['partner'];
      // workaround until PS updates the URL for Event Registration.
      if (this._partnerParameterName === 'Allianzassistance') {
          this._partnerParameterName = 'allianzregistration';
        }
      // tslint:disable-next-line:max-line-length
      this.router.navigate(['/' + this._partnerParameterName + '/policy/view'], { queryParams: {partner: partnerParam, pol: policyNumber, purchDate: purchDate, sys: sys}});
      return true;
    }

    this._partnerParameterName = route.params['partner'];

    // check if partner param is different
    // if it is, clear app state
    // if app state is cleared, set partner param in app state
    // if app state is cleared, get config and store it

    return this.contentManagementService.checkPartnerParam(this._partnerParameterName)
      .toPromise()
      .then(paramChanged => this.handlePartnerParamChanged(paramChanged))
      .then(config => this.handleAppState(config))
      .then(() => this.handleGlobalState())
      .then(() => true)
      .catch(err => {
        if (err instanceof HttpErrorResponse) {
          switch (err.status) {
            case 404:
            window.location.href = '/';
              break;
            default:
              // TODO: implement handler for failed response (500, etc.)
              break;
          }
        }
        return false;
      });
  }

  private handlePartnerParamChanged(paramChanged: boolean): Promise<Site> {

    const prom1 = new Promise<Site>((resolve, reject) => {
      if (paramChanged === true) {
        this.appStateService.clearAppState();
        return this.contentManagementService.getAgencyConfig(this._partnerParameterName)
          .toPromise()
          .then(config => { resolve(config); })
          .catch(err => { reject(err); });
      } else {
        resolve();
      }
    });

    return prom1;
  }

  private handleAppState(config: Site): Promise<any> {

    const prom1 = new Promise<boolean>((resolve, reject) => {

      if (config) {
        this.appStateService.setPartnerConfig(config);
        this.appStateService.setPartnerName(this._partnerParameterName);
      }

      this.appStateService.broadcastPartnerName();
      this.appStateService.broadcastAgencyInfo();
      this.appStateService.tryBroadcastActiveMyPoliciesLink();

      if (this.appStateService.getManageSessionActive() || this.appStateService.getPurchaseSessionActive()) {
        this.timeoutService.startSessionTimer();
      }

      resolve();
    });

    return prom1;
  }

  private handleGlobalState(): Promise<any> {
    return this.contentManagementService.tryGetGlobalContent().pipe(
      tap(gc => {
        this.appStateService.broadcastGlobalContent();
      })).toPromise();
  }
}
