<form [formGroup]="carRentalForm" (ngSubmit)="onSubmit()" class="form" novalidate>
  <div class="form-row">
    <div class="col-md-4">
      <div class="form-group">
        <label for="state">State of Residence</label>
        <select id="state" class="form-control form-control-sm" formControlName="state">
          <option value="" selected>Choose a state</option>
          <option *ngFor="let state of states" value="{{state.code}}">{{state.code}}-{{state.desc}}</option>
        </select>
        <app-input-validation [inputFormControl]="state"></app-input-validation>
      </div>
    </div>

    <div class="col-md-3">
      <div class="form-group">
        <label for="totalTripCost" placement="top" ngbTooltip="{{rentalCostTooltipMessage}}">Total Rental Cost <span class="fa fa-question-circle"></span></label>
        <div class="input-group input-group-sm">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1">$</span>
            </div>
          <input type="text" id="totalTripCost" class="form-control form-control-sm" formControlName="totalTripCost">
        </div>
        <app-input-validation [inputFormControl]="totalTripCost">
          <div class="validation-error__message" *ngIf="totalTripCost.errors?.pattern">Invalid format. Entry must be a number with up to two (2) decimal places.</div>
        </app-input-validation>
      </div>
    </div>

    <div class="col-sm-12 col-md-5">
      <div class="form-group">
        <label for="pickupDropOffDate">Pickup - Drop off Date</label>
        <p-calendar #rangeDatePicker class="custom" data-test-id="fromToDate" formControlName="pickupDropOffDate" selectionMode="range" [readonlyInput]="true" [minDate]="minDate" [numberOfMonths]="displayMonths" [showIcon]="true" (onClose)="onClose()" (onSelect)="onSelect()">
          <ng-template pTemplate="date" let-date>
            <span id= "{{date.month}}{{date.day}}" >{{date.day}}</span>
          </ng-template>
          <p-footer>
            <button type="button" mat-button class="link" (click)="onClose()">Done</button>
          </p-footer>
        </p-calendar>
        <app-input-validation [inputFormControl]="pickupDropOffDate">
          <div class="validation-error__message" *ngIf="pickupDropOffDate.invalid">This field is required.</div>
        </app-input-validation>
      </div>
    </div>
  </div>

  <app-form-validation [errorMessages]="errors"></app-form-validation>
  <div class="form-row">
    <div class="col">
      <app-dynamic-button [compId]="componentId + 'car-rental-btn'" type="submit" class="pull-right" [disabled]="buttonDisabled" [buttonText]="quoteButtonText" [buttonHoverColor]="quoteButtonHoverColor"
        [buttonBackgroundColor]="quoteButtonColor"></app-dynamic-button>
    </div>
  </div>
</form>