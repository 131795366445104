// 500 returned from API
export const SERVICE_DOWN = 'Server error occurred. Please try again later.';

export const NO_POLICIES = 'No policies found, please try a different search criteria.';

export const CANNOT_FILE_CLAIMS = 'You may not file claims for this policy.';

export const CANNOT_CANCEL_POLICY = 'This policy may not be canceled.';

export const EMAIL_SUCCESS = 'Email sent successfully';

export const EMAIL_FAILED = 'Email sending failed. Please try again later.';

export const CONTACT_SUCCESS = 'We will get back to you as soon as possible, within normal business hours.';

export const CONTACT_FAILED = 'Contact message sending failed. Please try again.';

export const VALIDATION_ERRORS = 'There are errors in the form.  Please review the form and try again.';

export const CANCEL_POLICY_CONFIRMATION_MESSAGE = 'Are you sure you would like to cancel your policy?';

export const CANCEL_POLICY_CONFIRMATION_TITLE = 'Cancel Policy Confirmation';

export const EMAIL_QUOTE_FAILED = `An error occured while emailing quote.  Please reload the page and try again.`;

export const EMAIL_QUOTE_VALIDATION_FAILED = 'Please fill out the required fields before emailing the quote.';

export const GET_FAQ_FAILED = `An error occured while retrieving FAQs.  Please reload the page and try again.`;

export const GET_COVERAGE_OPTIONS_FAILED = `An error occured while retrieving Coverage Options.  Please reload the page and try again.`;