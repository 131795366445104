import { Observable ,  ReplaySubject } from 'rxjs';
import { ConfirmationDialogComponent } from './../../components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { AlertTypeEnum } from "../alert-type.enum";
import * as AlertMessages from '../../../shared/constants/alert-messages.constants';
import { InfoDialogComponent } from '../../components/dialogs/info-dialog/info-dialog.component';
import { ContentDialogComponent } from '../../components/dialogs/content-dialog/content-dialog.component';

type AlertPayload = [string, AlertTypeEnum];

@Injectable()
export class AlertService {

  private _messageSource = new ReplaySubject<AlertPayload>();

  public message$ = this._messageSource.asObservable();

  constructor(
    public dialog: MatDialog
  ) { }

  error(message = AlertMessages.SERVICE_DOWN) {
    this._messageSource.next([message, AlertTypeEnum.ERROR]);
  }

  warn(message: string) {
    this._messageSource.next([message, AlertTypeEnum.WARN]);
  }

  success(message: string) {
    this._messageSource.next([message, AlertTypeEnum.SUCCESS]);
  }

  confirm(message: string, title?: string): Observable<boolean> {
    return this.openDialog(message, title);
  }

  infoDialog(message: string, title = 'Confirmation'): void {
    this.openInfoDialog(message, title, AlertTypeEnum.INFO);
  }

  contentDialog(message: any, title?: string): void {
    this.openContentDialog(message, title, AlertTypeEnum.INFO);
  }

  errorDialog(message = AlertMessages.SERVICE_DOWN, title = 'Error'): void {
    this.openInfoDialog(message, title, AlertTypeEnum.ERROR);
  }

  private openInfoDialog(message: string, title: string, type: AlertTypeEnum): void {
    this.dialog.open(InfoDialogComponent, {
      data: { title: title , message: message, type: type },
      width: '400px'
    });
  }

  private openContentDialog(message: string, title: string, type: AlertTypeEnum): void {
    this.dialog.open(ContentDialogComponent, {
      data: { title: title , message: message, type: type },
      width: '726px'
    });
  }

  private openDialog(message: string, title?: string): Observable<boolean> {
    let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { title: title || `Confirmation`, message: message }
    });

    return dialogRef.afterClosed();
  }
}


