import { Environment } from './../app/shared/models/environment.interface';

export const environment: Environment = {
  encSecret:'4512631236589784',
  production: false,
  apiGatewayUrl: 'https://uat.etravelprotection.com/es-api',
  claimsUrl: 'https://uat.etravelprotection.com/claims',
  version: '0.0.0',
  tcUrl: 'http://uatpartner.allianztravelinsurance.com/AA/PopUps/TermsAndConditions.aspx',
  purchaseAgreementUrl: 'https://www.agentmaxonline.com/agentmaxweb/documents/GeneralPurchaseTerms.pdf',
  defaultPartnerName: 'allianz',
  etravelDomainUrl: 'https://www.etravelprotection.com',
  eventTicketDomainUrl: 'https://www.eventticketprotection.com',
  eventRegistrationDomainUrl: 'https://www.eventregistrationprotection.com',
  sessionDomainUrl: 'https://www.sessionregistrationprotection.com',
  seasonDomainUrl: 'https://www.seasonpassprotection.com',
  eTravelDomainTagURL: 'https://content.allianzpartnerservices.com/remote-content/v3/privacy-min.js?domain=etravel',
  eventTicketProtectionTagURL: 'https://content.allianzpartnerservices.com/remote-content/v3/privacy-min.js?domain=etp',
  eventRegistrationProtectionTagURL: 'https://content.allianzpartnerservices.com/remote-content/v3/privacy-min.js?domain=erp',
  defaultDomainTagURL: 'https://content.allianzpartnerservices.com/remote-content/v3/privacy-min.js?domain=rickd',
  etravelGoogleTag: 'GTM-PKXMTZ7',
  eventTicketProtectionGoogleTag: 'GTM-W7DLXJ2',
  eventRegistrationGoogleTag: 'GTM-576TCRX',
  sessionDomainGoogleTag: 'GTM-PR52GCJ',
  seasonDomainGoogleTag: 'GTM-JHZ5P3B',
  // innerHTMLScript: 'function(w,d,s,l,i){w[l]=w[l]||[];w[l].push('+
  //   '{"gtm.start": new Date().getTime(),event:"gtm.js"}'+
  //   ');var f=d.getElementsByTagName(s)[0],'+
  //   'j=d.createElement(s),dl=l!="dataLayer"?"&l="+l:"";j.async=true;j.src='+
  //   'https://www.googletagmanager.com/gtm.js?id="+i+dl+'+ 
  //   '"&gtm_auth=EdodvqwPhD--4HC-ufmj4Q&gtm_preview=env-16&gtm_cookies_win=x";'+
  //   'f.parentNode.insertBefore(j,f);'+
  //   '})(window,document,"script","dataLayer","{gtmId}");'
  innerHTMLScript: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push(
    {'gtm.start': new Date().getTime(),event:'gtm.js'}
    );var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=EdodvqwPhD--4HC-ufmj4Q&gtm_preview=env-16&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','{gtmId}');`
};
