export enum QuoteComponentEnum {
    CarRental = 'rental-car',
    None = 'none',
    Rail = 'rail',
    TravelNonAgeRated = 'travel',
    TravelAgeRated = 'travel-age-rated',
    EventTicket = 'event-ticket',
    EventRegistration = 'event-registration',
    Annual = 'annual',
    SessionSeason = 'session-season',
    TravelHotel = 'travel-hotel',
    TravelHotelSameday = 'travel-hotel-sameday'
}
