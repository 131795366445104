import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from './../../../../environments/environment';
import { KeyValue } from '../../models';

@Injectable()
export class ContactUsService {

  constructor(
    private http: HttpClient
  ) { }

  sendContactRequest(contactForm: {
    tokenG:string,
    name: string,
    email: string,
    policyNumber: string,
    subject: number,
    question: string
  }): Observable<Object> {
    return this.http.post(`${environment.apiGatewayUrl}/contact-us`, contactForm);
  }

  public getContactUsSubjects(): Observable<KeyValue[]> {
    return this.http.get<KeyValue[]>(`${environment.apiGatewayUrl}/contact-us/subject`);
  }


}
