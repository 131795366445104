<div class="faq-categories" id="categories">
  <a class="category-links" *ngFor="let category of faqCategories" href="javascript:"  [ngx-scroll-to]="'#'+ category" [ngx-scroll-to-offset]="100">{{category}}</a>
</div>

<div class="row">
    <div class="col-lg-8 col-md-6">
      <mat-accordion *ngFor="let f of faq; let first = first" multi="true">
        <div class="category-heading-wrapper">
          <h1 id="{{f.displayName}}">{{f.displayName}}</h1>
          <a href="javascript:" [ngx-scroll-to]="'categories'" [ngx-scroll-to-offset]="-200" *ngIf="!first">Top of Page</a>
        </div>
        <mat-expansion-panel *ngFor="let q of f.questions">
          <mat-expansion-panel-header [style.background-color]="primaryBarColor" [collapsedHeight]="'auto'" [expandedHeight]="'auto'">
            <div [innerHtml]="q.question"></div>
          </mat-expansion-panel-header>
          <div [innerHtml]="q.answer"></div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div class="col-lg-4 col-md-6">
      <app-contact-us></app-contact-us>
    </div>
  </div>
