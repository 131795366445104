import { Component, OnInit } from '@angular/core';
import { ContentManagementService } from '../../../shared/services/content-management.service';

@Component({
  selector: 'app-widget-wrapper',
  templateUrl: './widget-wrapper.component.html',
  styleUrls: ['./widget-wrapper.component.scss']
})
export class WidgetWrapperComponent implements OnInit {
    /*
  * User can confiure whether to show Get a Quote tab on a per site basis.
  */
 showQuoteTab: boolean;

 /*
 * User can confiure whether to show Manage a Policy tab on a per site basis.
 */
 showPolicyTab: boolean;

 showClaimTab: boolean;

 quoteWidgetSelection: string;

 destinationSelection: string;

 /*
 * Tab background color.
 */
 tabBackgroundColor: string;

 /*
 * Tab hover color.
 */
 tabHoverColor: string;

 /*
 * Configure the text of the submit button for the Get a Quote tab.
 */
 quoteButtonText: string;

 /*
 * Quote tab submit button background color.
 */
 quoteButtonColor: string;

 /*
 * Quote tab submit button background hover color.
 */
 quoteButtonHoverColor: string;

 /*
 * Hero Image
 */
 heroImageUrl: string;

 /*
 * Nestle quote widget within hero image.
 */
 widgetOnHero: boolean;

 /*
 * Quote Tab Caption
 */
 quoteTabCaption: string;

  constructor(private contentManagementService: ContentManagementService) { }

  ngOnInit() {

    this.contentManagementService.tryGetAgencyConfig().subscribe(config => {
      this.tabHoverColor = config.colors.activeTabColor;
      this.quoteButtonColor = config.colors.primaryButtonColor;
      this.quoteButtonHoverColor = config.colors.secondaryButtonColor;
      this.quoteButtonText = config.quoteButtonText;
      this.heroImageUrl = config.homeHeroImage;
      this.showPolicyTab = config.displayPolicyTab;
      this.showClaimTab = config.displayClaimTab;
      this.quoteWidgetSelection = config.quoteWidget;
      this.destinationSelection = config.destination;
      this.widgetOnHero = config.widgetOnHero;
      this.quoteTabCaption = config.quoteTabCaption;

      const sharedContentBlocks = this.contentManagementService.getContentBlocks(config.homeContents);
    });
  }

}
