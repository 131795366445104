export enum ButtonEnum {
  GetQuote = 'Get a Quote',
  UpdateQuote = 'Update Quote',
  ManagePolicy = 'Manage a Policy',
  Claims = 'Claim',
  FileClaim = 'File a Claim',
  CheckClaim = 'Check a Claim',
  Benefits = 'Benefits',
  Description = 'Description',
  ContactUs = 'Contact Us'
}
