<div class="home-container">
    <app-hero-image  [style.minHeight.px]="height" (getImgHeight)="getImgHeight($event)"  [nestled]="widgetOnHero" class="hero-img-home" [class.nestled-img-js]="widgetOnHero" [src]="heroImageUrl"></app-hero-image>
    <div [class.nestled-js]="widgetOnHero" class="row widget-container">
        <app-widget (getWidgetHeight)="getWidgetHeight($event)" id="widget" class="col-md-8"
        [showQuoteTab]="showQuoteTab"
        [showPolicyTab]="showPolicyTab"
        [showClaimTab]="showClaimTab"
        [nonActiveTabColor]="nonActiveTabColor"
        [tabHoverColor]="tabHoverColor"
        [quoteButtonText]="quoteButtonText"
        [quoteButtonColor]="quoteButtonColor"
        [quoteButtonHoverColor]="quoteButtonHoverColor"
        [quoteWidgetSelection]="quoteWidgetSelection"
        [destinationSelection]="destinationSelection"
        [quoteTabCaption]="quoteTabCaption"
        ></app-widget>
        <div *ngIf="!widgetOnHero" [class.col-md-4]="!widgetOnHero"  class="content-container--why-buy d-flex right-of-widget">
            <app-content-block *ngIf="firstContentBlock | async as cb" [content]="cb"></app-content-block>
        </div>

    </div>
    <div *ngIf="widgetOnHero" [class.col-md-12]="widgetOnHero" class="content-container--why-buy d-flex align-items-center">
        <app-content-block *ngIf="firstContentBlock | async as cb" [content]="cb"></app-content-block>
    </div>
    <div class="row">
        <app-content-block-container class="col-12" [maxBlocks]="MAX_BLOCKS" *ngIf="contentBlocks | async as cbs" [contentBlocks]="cbs">
        </app-content-block-container>
    </div>
</div>
