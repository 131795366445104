import { State } from '../../shared/models/state.interface';
import { Injector, Injectable } from '@angular/core';
import { Subject ,  Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { QuoteComponentEnum } from '../models/quote-component.enum';
import { GenerateQuote } from '../models/generate-quote.interface';
import { ESitesNgbDateParserFormatter } from './date-parser-formatter';
import { Quote } from '../models/quote.interface';
import { NetworkService } from '../../shared/services/network.service';
import { dateOfBirthToAge } from '../../shared/common/date-of-birth-to-age';
import { ProductBenefitInfo } from '../../policy/models';


@Injectable()
export class QuoteService extends NetworkService {
  private checkoutSource = new Subject<QuoteComponentEnum>();

  public checkoutObservable$ = this.checkoutSource.asObservable();

  constructor(private http: HttpClient, private injector: Injector) { 
    super(injector);
  }

  public setComponent(component: QuoteComponentEnum) {
    this.checkoutSource.next(component);
  }

  public getRailQuote(state: string, cost: number, departureDate: string, returnDate: string, numTravelers: number): Observable<Quote> {
    const gq = <GenerateQuote>{
      state: state,
      cost: cost,
      departureDate: departureDate,
      returnDate: returnDate,
      numberOfTravelers: numTravelers,
      type: QuoteComponentEnum.Rail
    };

    return this.request(this.http.put<Quote>(`${environment.apiGatewayUrl}/quote`, gq));
  }

  public getCarRentalQuote(state: string, cost: number, departureDate: string, returnDate: string): Observable<Quote> {
    const gq = <GenerateQuote>{
      state: state,
      cost: cost,
      departureDate: departureDate,
      returnDate: returnDate,
      type: QuoteComponentEnum.CarRental
    };
    return this.request(this.http.put<Quote>(`${environment.apiGatewayUrl}/quote`, gq));
  }

  public getTravelQuote(state: string, cost: number, destination: string,
    departureDate: string, returnDate: string, numTravelers: number, frequentFlyer: boolean): Observable<Quote> {
    const gq = <GenerateQuote>{
      state: state,
      cost: cost,
      destination: destination,
      departureDate: departureDate,
      returnDate: returnDate,
      numberOfTravelers: numTravelers,
      frequentFlyer: frequentFlyer,
      type: QuoteComponentEnum.TravelNonAgeRated

    };

    return this.request(this.http.put<Quote>(`${environment.apiGatewayUrl}/quote`, gq));

  }

  public getTravelAgeRatedQuote(state: string, cost: number, destination: string,
    departureDate: string, returnDate: string, depositDate: string, age: number[] | string[], ageOrDob: AgeOrDobEnum): Observable<Quote> {
    const gq = <GenerateQuote>{
      state: state,
      cost: cost,
      destination: destination,
      departureDate: departureDate,
      returnDate: returnDate,
      initialDepositDate: depositDate,
      type: QuoteComponentEnum.TravelAgeRated
    };

    switch (ageOrDob) {
      case AgeOrDobEnum.Age:
        gq.age = age as number[];
        gq.dateOfBirth = [];
        break;
      case AgeOrDobEnum.Dob:
        gq.dateOfBirth = age as string[];
        gq.age = (age as string[]).map(a => {
          return this.dobToAge(a);
        });
        break;
    }

    return this.request(this.http.put<Quote>(`${environment.apiGatewayUrl}/quote`, gq));

  }

  public getEventTicketQuote(state: string, cost: number, numberOfTickets: number, beginDate: string, endDate: string): Observable<Quote> {
    const gq = <GenerateQuote>{
      state: state,
      cost: cost,
      beginDate: beginDate,
      numberOfTickets: numberOfTickets,
      endDate: endDate,
      type: QuoteComponentEnum.EventTicket
    };

    return this.request(this.http.put<Quote>(`${environment.apiGatewayUrl}/quote`, gq));
  }

  public getAnnualQuote(state: string, numTravelers: number, destination: string): Observable<Quote> {
    const gq = <GenerateQuote>{
      state: state,
      destination: destination,
      numberOfTravelers: numTravelers,
      type: QuoteComponentEnum.Annual
    };

    return this.request(this.http.put<Quote>(`${environment.apiGatewayUrl}/quote`, gq));

  }

  public getSessionSeasonQuote(state: string, cost: number, numberOfPasses: number, beginDate: string, endDate: string): Observable<Quote> {
    const gq = <GenerateQuote>{
      state: state,
      cost: cost,
      beginDate: beginDate,
      numberOfPasses: numberOfPasses,
      endDate: endDate,
      type: QuoteComponentEnum.EventTicket
    };

    return this.request(this.http.put<Quote>(`${environment.apiGatewayUrl}/quote`, gq));
  }

  private dobToAge(dob: string): number {
    const birthDateNGB = (new ESitesNgbDateParserFormatter()).parse(dob);
    const birthDate = new Date(`${birthDateNGB.year}-${this.checkDate(birthDateNGB.month)}-${this.checkDate(birthDateNGB.day)}`);

    return dateOfBirthToAge(birthDate);
  }

  // Prepend zeros to single digits
  checkDate(val) {
    return (val < 10 ? '0' : '') + val;
  }

  public getErrors(q: Quote): string[] {
    const errorArray = q.quote.tripInformation.validationMessages;
    if (!errorArray) return [];
    let errors = [];
    errorArray.forEach(err => {
      errors.push(err.description);
    })
    return errors;
  }
}

export enum AgeOrDobEnum {
  Age = 1,
  Dob
}
