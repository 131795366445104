import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { PrivacyComponent } from "../../components/privacy/privacy.component";


@Injectable()
export class CanDeactivatePrivacyGuard implements CanDeactivate<unknown> {

  canDeactivate(component: PrivacyComponent, currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot) : Observable<boolean> | Promise<boolean> | boolean {
    return component.deleteScriptWhenLeaveRoute();
  }

}