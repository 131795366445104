import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';



@Injectable()
export class ConfigService {

  constructor(private http: HttpClient) { }

  public loadEnvConfig() {
    return this.http.get('config/config.json', {"headers":{ 'shouldAuth': 'false'}}).toPromise();
  }

}
