import { ContentManagementService } from './../shared/services/content-management.service';
import { Router, ActivatedRoute } from '@angular/router';
import { PolicyListing } from './../policy/models/policy-listing.interface';
import { ESitesNgbDateParserFormatter } from './services/date-parser-formatter';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { QuoteService } from './services/quote.service';
import { Component, OnInit, OnDestroy, AfterViewInit, Input, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { Type, OnChanges, AfterContentInit, Output, EventEmitter, ElementRef } from '@angular/core';
import { QuoteComponentEnum } from './models/quote-component.enum';
import { DestinationEnum } from './models/destination.enum';
import { environment } from '../../environments/environment';
import { PolicyService } from '../policy/services/policy.service';
import { AppStateService } from '../shared/services/app-state.service';
import { Site } from '../shared/models';
import { ButtonEnum } from './models/button.enum';
import { GoogleAnalyticsEventsService } from '../shared/services/google-analytics-events.service';

@Component({
  selector: 'app-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: ESitesNgbDateParserFormatter },
    PolicyService
  ]
})
export class WidgetComponent implements OnInit, AfterContentInit, AfterViewInit {

  public selectedTab: QuoteModuleTabEnum;
  public hoveringTab: QuoteModuleTabEnum | null = null;

  /*
  * User can configure whether to show Get a Quote tab on a per site basis.
  */
  showQuoteTab = true;

  fileClaimUrl = `${environment.claimsUrl}/policy-search`;
  trackClaimUrl = `${environment.claimsUrl}/claim-search`;

  /*
  * User can configure whether to show Manage a Policy tab on a per site basis.
  */
  @Input() showPolicyTab = true;

  @Input() showClaimTab = true;

  /*
  * Tab hover color.
  */
  @Input() tabHoverColor: string;

  @Input() nonActiveTabColor: string;

  /*
  * Configure the text of the submit button for the Get a Quote tab.
  */
  @Input() quoteButtonText: string;

  /*
  * Quote tab submit button background color.
  */
  @Input() quoteButtonColor: string;

  /*
  * Quote Tab Caption
  */
 @Input() quoteTabCaption: string;

  /*
  * Quote tab submit button background hover color.
  */
  @Input() quoteButtonHoverColor: string;

  @Input() quoteWidgetSelection: string;

  @Input() destinationSelection: string;

  @Output() getWidgetHeight = new EventEmitter<number>();

  @ViewChild('widgetHeight') widgetHeight: ElementRef;

  widget: QuoteComponentEnum;
  manageTabCaption: string;
  claimTabCaption: string;
  managePolicyTabHeading: string;
  tabBackgroundColor = '#ddd';

  constructor(
    private QuoteService: QuoteService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private policyService: PolicyService,
    private appStateService: AppStateService,
    private contentManagementService: ContentManagementService,
    private ga: GoogleAnalyticsEventsService
  ) { }

  onFileClaimLinkClicked() {
    this.ga.buttonClick(ButtonEnum.FileClaim);
    window.open(this.fileClaimUrl, '_blank');
  }

  onClaimClicked() {
    this.ga.buttonClick(ButtonEnum.Claims);
  }

  onManagePolicyClicked() {
    this.ga.buttonClick(ButtonEnum.ManagePolicy);
  }

  onGetAQuoteClicked() {
    this.ga.buttonClick(ButtonEnum.GetQuote);
  }

  onTrackClaimLinkClicked() {
    this.ga.buttonClick(ButtonEnum.CheckClaim);
    window.open(this.trackClaimUrl, '_blank');
  }

  ngOnInit() {
    this.widget = <QuoteComponentEnum>this.quoteWidgetSelection;
    this.showQuoteTab = this.widget !== QuoteComponentEnum.None;

    this.route.queryParams.subscribe(params => {
      switch (params['action']) {
        case 'manage':
          this.selectedTab = QuoteModuleTabEnum.Manage;
          break;
        case 'claim':
          this.selectedTab = QuoteModuleTabEnum.Claim;
          break;
        default:
          this.preselectTab(this.selectedTab);
      }
    });

    this.contentManagementService.tryGetGlobalContent()
      .subscribe(gc => {
        this.manageTabCaption = gc.policyTabCaption;
        this.claimTabCaption = gc.claimTabCaption;
      });
  }

  private preselectTab(tabId: QuoteModuleTabEnum) {
    this.selectedTab = QuoteModuleTabEnum.Quote;

    if (!this.showQuoteTab) {
      if (!this.showPolicyTab) {
        this.selectedTab = QuoteModuleTabEnum.Claim;
      } else {
        this.selectedTab = QuoteModuleTabEnum.Manage;
      }
    }
  }

  ngAfterContentInit() {
    // if (this.showQuoteTab) {
    //   this.resolveChildComponent(this.widget);
    // }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.getWidgetHeight.emit(this.widgetHeight.nativeElement.offsetHeight + 40);
    }, 500);
  }

  onPolicyListingsReceived($event: Array<PolicyListing>) {
    if ($event.length == 1) {
      this.policyService.getPolicy($event[0]).subscribe(p => {
        this.appStateService.setCurrentPolicy(p);
        this.router.navigate(['policy/view'], { relativeTo: this.route.parent });
      })
    } else if ($event.length > 1) {
      this.router.navigate(['policy'], { relativeTo: this.route.parent });
    }
  }
}

enum QuoteModuleTabEnum {
  Quote = 1,
  Manage,
  Claim
}
