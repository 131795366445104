import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-dynamic-button',
  templateUrl: './dynamic-button.component.html',
  styleUrls: ['./dynamic-button.component.scss']
})
export class DynamicButtonComponent implements OnInit {

  @Input() buttonText: string;

  @Input() buttonBackgroundColor: string;

  @Input() buttonHoverColor: string;

  @Input() disabled: boolean;

  @Input() compId: string;

  hovering: boolean;

  constructor() { }

  ngOnInit() {
  }

}
