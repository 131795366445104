import { Component, OnInit, Input } from '@angular/core';
import { ContentManagementService } from '../../shared/services/content-management.service';

@Component({
  selector: 'app-warning-banner',
  templateUrl: './warning-banner.component.html',
  styleUrls: ['./warning-banner.component.scss']
})
export class WarningBannerComponent implements OnInit{

  @Input() displayBanner: boolean;
  @Input() domain: string;

  constructor(private cmsService: ContentManagementService) { }

  ngOnInit() {
  }

}
