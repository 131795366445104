import { InputField } from '../../shared/models';

export class FlightInformation {
    formattedFlightDate: InputField<string>;
    flightNumber: InputField<string>;
    airlineFSCode: InputField<string>;
    airlineName: InputField<string>;
    airportFSCode: InputField<string>;
    airportMatchString: InputField<string>;
    airportName: InputField<string>;
    isEditable: boolean;

    constructor() {
        this.formattedFlightDate = { value: '', isValid: true, isEditable: undefined, errorMessages: [] }
        this.flightNumber = { value: '', isValid: true, isEditable: undefined, errorMessages: [] }
        this.airlineFSCode = { value: '', isValid: true, isEditable: undefined, errorMessages: [] }
        this.airlineName = { value: '', isValid: true, isEditable: undefined, errorMessages: [] }
        this.airportFSCode = { value: '', isValid: true, isEditable: undefined, errorMessages: [] }
        this.airportMatchString = { value: '', isValid: true, isEditable: undefined, errorMessages: [] }
        this.airportName = { value: '', isValid: true, isEditable: undefined, errorMessages: [] }
    }
}
